import {Component, Inject, OnInit} from '@angular/core'
import {
    SnowVisibilitiesInfo, SnowVisibilitiesMenuDayNightOption,
    SnowVisibilitiesMenuIntensity,
    SnowVisibilitiesMenuTemperatureOption, SnowVisibilitiesMenuValue
} from "../shared/Api.service";
import { NgFor, NgIf } from '@angular/common';
import {DialogComponent, DialogContentComponent, DialogHeaderComponent} from "swx.front-end-lib";
import {CdkDrag, CdkDragHandle} from "@angular/cdk/drag-drop";
import {DIALOG_DATA, DialogRef} from "@angular/cdk/dialog";

@Component({
    templateUrl: 'IntensityDialog.component.html',
    standalone: true,
    imports: [NgFor, NgIf, DialogComponent, DialogHeaderComponent, DialogContentComponent, CdkDrag, CdkDragHandle]
})
export class IntensityDialogComponent implements OnInit {
    title!: string;
    selectedIntensity?: SnowVisibilitiesMenuIntensity;
    selectedVisibility?: SnowVisibilitiesMenuValue;
    selectedTemperatureOption: SnowVisibilitiesMenuTemperatureOption;
    selectedDayNightOption?: SnowVisibilitiesMenuDayNightOption;
    snowVisibilities: SnowVisibilitiesInfo;
    
    constructor(@Inject(DIALOG_DATA) private data,
                private dialogRef: DialogRef,
        ) {
    }
    
    ngOnInit() {
        this.title = this.data.title;
        this.snowVisibilities = this.data.snowVisibilities;
        this.selectedIntensity = this.data.selectedIntensity;
        this.selectedVisibility = this.data.selectedVisibility;
        this.selectedTemperatureOption = this.data.selectedTemperatureOption;
        this.selectedDayNightOption = this.data.selectedDayNightOption;
    }

    selectSnowVisibility(visibilityOption: SnowVisibilitiesMenuValue) {
        this.dialogRef.close({
            visibility: visibilityOption,
            intensity: null,
        });
    }

    selectIntensity(intensity: SnowVisibilitiesMenuIntensity) {
        this.dialogRef.close({
            visibility: null,
            intensity: intensity,
        });
    };

    cancel() {
        this.dialogRef.close();
    }
}
