import {Component, Input, OnInit, ViewChild, ViewContainerRef} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import moment from 'moment';
import 'moment-timezone';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ValueFormatterParams } from 'ag-grid-community';
import { RootScope } from '../shared/RootScope.service';
import { ApiService } from '../shared/Api.service';
import { TranslateService, NotificationService, FileDownloadService, AuthenticationService, DialogService, StorageService, GridComponent, IAgGridColGroupDef, ValueFormatters, IAgGridColumnDef, yesNo, SwxModule, GridModule } from 'swx.front-end-lib';
import { HasPermissionService, HasPermissionPipe } from '../shared/HasPermission.pipe';
import { TicketTrackingSystemService } from '../shared/TicketTrackingSystem.service';
import { DeicingRecordSignatureDialogComponent } from "../deicingRecordSignatureDialog/DeicingRecordSignatureDialog.component";
import { NgIf, NgFor } from '@angular/common';
import { FormsModule } from '@angular/forms';

@Component({
    templateUrl: 'DeicingRecordGrid.component.html',
    styleUrls: ['./DeicingRecordGrid.component.scss'],
    standalone: true,
    imports: [
        SwxModule,
        FormsModule,
        NgIf,
        NgFor,
        GridModule,
        HasPermissionPipe,
    ],
})
export class DeicingRecordGridComponent implements OnInit {
    @ViewChild('grid', { static: true }) grid: GridComponent;
    rowSelection = null;
    airlines = this.api.Airline.query();
    airports = this.api.Airport.query();
    serviceProviders = this.api.ServiceProvider.query({ OnlyAssignedItems: true });
    deicingRecordFormats = this.api.DeicingRecordFormat.query();
    datePickerOptions: any;
    query: any;
    components = { truckCellRenderer: DeicingRecordTruckCellRendererComponent, };
    columnDefs: IAgGridColGroupDef[];
    useUtc: boolean = true;
    useFahrenheit: boolean = false;
    useGallons: boolean = false;
    useUtcStorageKey: string = 'preferences.useUtc_v1';
    useFahrenheitStorageKey = 'preferences.useFahrenheit_v1';
    useGallonsStorageKey = 'preferences.useGallons_v1';
    private storeKey: string; 
    uploadId: number;
    role: string;

    constructor(
        private router: Router,
        private viewContainerRef: ViewContainerRef,
        private activatedRoute: ActivatedRoute,
        private $root: RootScope,
        public api: ApiService,
        private translateService: TranslateService,
        private notification: NotificationService,
        private authenticationService: AuthenticationService,
        private hasPermissionService: HasPermissionService,
        private ticketTrackingSystem: TicketTrackingSystemService,
        private dialogService: DialogService,
        private storageService: StorageService,
        private formatters: ValueFormatters,
        private fileDownloadService: FileDownloadService,
    ) {
        // Load UTC usage preference
        const storedUtcPreference = this.storageService.load(this.useUtcStorageKey);

        if (storedUtcPreference) {
            this.useUtc = storedUtcPreference === 'yes';
        }

        // Load Fahrenheit usage preference
        const storedFahrenheitPreference = this.storageService.load(this.useFahrenheitStorageKey);

        if (storedFahrenheitPreference) {
            this.useFahrenheit = storedFahrenheitPreference === 'yes';
        }

        // Load gallons usage preference
        const storedGallonsPreference = this.storageService.load(this.useGallonsStorageKey);

        if (storedGallonsPreference) {
            this.useGallons = storedGallonsPreference === 'yes';
        }

        this.uploadId = this.activatedRoute.snapshot.params['uploadId'];
        this.role = this.activatedRoute.snapshot.data['role'];
        //this.router.navigate(['/deicingRecordsData']);

        this.datePickerOptions = {
            maxDate: new Date(new Date().getUTCFullYear(), new Date().getUTCMonth(), new Date().getUTCDate(), new Date().getUTCHours(), new Date().getUTCMinutes(), new Date().getUTCSeconds()),
        };

        this.storeKey = 'deicingRecordQueryV7_' + this.role;
        
        this.query = this.storageService.load(this.storeKey);
        if (!this.query) {
            this.resetQuery();
        }

        this.buildColumnDefs();
    }
    
    ngOnInit() {
        if (this.uploadId) {
            this.query = {
                Filters: [
                    {
                        Field: 'DeicingRecord.DeicingUploadId',
                        Operator: 'Equals',
                        Type: 'integer',
                        Value: this.uploadId
                    }
                ]
            };

            this.grid.gridReadyPromise.then(() => {
                this.grid.refresh();
            });
        }
    }
    
    buildColumnDefs() {
        let columnDefs: IAgGridColumnDef[] = [
            { colId: 'DateTime', field: 'DateTime', headerName: 'Date (' + (this.useUtc ? 'UTC' : 'airport local') + ')', valueFormatter: c => c.value ? moment.utc(c.value).format(this.$root.dateFormat) : '', width: 140, pinned: 'left', filterType: 'date', checkboxSelection: () => this.hasPermissionService.hasPermission('DeicingDeleteRecords') },

            {
                colId: 'DeicingRecord.Id', field: 'DeicingRecord.Id', headerName: '#', width: 60, pinned: 'left', filterType: 'integer',
                cellRenderer: c => {
                    if (c.value) {
                        var container = document.createElement('div');
                        container.innerHTML = '<a href="/deicingRecords/' + c.value + '" onclick="event.preventDefault();">' + c.value + '</a>';
                        container.querySelector('a').addEventListener('click', e => this.router.navigateByUrl(e.target['getAttribute']('href')));
                        return container;
                    }

                    return '';
                }
            },

            { colId: 'DeicingRecord.Identifier', field: 'DeicingRecord.Identifier', headerName: 'Identifier', width: 80, pinned: 'left' },
            { colId: 'DeicingRecord.Format', field: 'DeicingRecord.Format', headerName: 'Source', valueFormatter: this.deicingRecordFormatValueFormatter(), width: 100, filterType: 'enum', source: 'DeicingRecordFormat' },
            { colId: 'CreatedByName', field: 'CreatedByName', headerName: 'Created by', width: 120 },
            { colId: 'DeicingRecord.CreatedDate', field: 'DeicingRecord.CreatedDate', headerName: 'Created date UTC', width: 150, valueFormatter: this.formatters.utcDateTimeFormatter(), filterType: 'date' },
            { colId: 'AirlineName', field: 'AirlineName', headerName: 'Airline', width: 120 },
            { colId: 'AirportCode', field: 'AirportCode', headerName: 'Airport', width: 85 },
            { colId: 'ServiceProviderName', field: 'ServiceProviderName', headerName: 'Service provider', width: 120 },
            { colId: 'DeicingRecord.FlightNumber', field: 'DeicingRecord.FlightNumber', headerName: 'Flight #', width: 100 },
            { colId: 'DeicingRecord.EquipmentTypeIdentifier', field: 'DeicingRecord.EquipmentTypeIdentifier', headerName: 'Aircraft type', width: 100 },
            { colId: 'DeicingRecord.RegistrationNumber', field: 'DeicingRecord.RegistrationNumber', headerName: 'Aircraft Registration', width: 100 },
            { colId: 'DeicingRecord.TailNumber', field: 'DeicingRecord.TailNumber', headerName: 'Tail #', width: 80 },
            { colId: 'DeicingRecord.DeicingUploadId', field: 'DeicingRecord.DeicingUploadId', headerName: 'Deicing upload #', width: 80, initialHide: true, },
        ];

        if (this.role == 'deicingRecords' || this.role == 'airline') columnDefs.push(
            { colId: 'DeicingRecord.DeicingLocation', field: 'DeicingRecord.DeicingLocation', headerName: 'Deicing location', width: 100 },
        );

        if (this.role == 'deicingRecords') columnDefs.push(
            { colId: 'DeicingRecord.ConfirmNozzleTemperature', field: 'DeicingRecord.ConfirmNozzleTemperature', headerName: 'Nozzle Temperature Checked', width: 100, cellRenderer: yesNo, filterType: 'boolean' },
        );

        columnDefs.push(
            { colId: 'DeicingStartTime', field: 'DeicingStartTime', headerName: 'Deicing start time (' + (this.useUtc ? 'UTC' : 'airport local') + ')', width: 70, valueFormatter: this.formatters.utcTimeFormatter(), filterType: 'date' },
            { colId: 'HoldoverStartTime', field: 'HoldoverStartTime', headerName: 'Holdover/Anti-ice start time (' + (this.useUtc ? 'UTC' : 'airport local') + ')', width: 70, valueFormatter: this.formatters.utcTimeFormatter(), filterType: 'date' },
            { colId: 'DeicingCompleteTime', field: 'DeicingCompleteTime', headerName: 'De/Anti-ice finish time (' + (this.useUtc ? 'UTC' : 'airport local') + ')', width: 70, valueFormatter: this.formatters.utcTimeFormatter(), filterType: 'date' },
            { colId: 'CancelledTime', field: 'CancelledTime', headerName: 'Deicing cancelled time (' + (this.useUtc ? 'UTC' : 'airport local') + ')', width: 70, valueFormatter: this.formatters.utcTimeFormatter(), filterType: 'date' },
            { colId: 'DeicingRecord.ConfirmationSlip', field: 'DeicingRecord.ConfirmationSlip', headerName: 'Confirmation slip', width: 100 },
        );

        if (this.role == 'deicingRecords') columnDefs.push(
            { colId: 'InitialCallTime', field: 'InitialCallTime', headerName: 'Initial call time (' + (this.useUtc ? 'UTC' : 'airport local') + ')', width: 70, valueFormatter: this.formatters.utcTimeFormatter(), filterType: 'date' },
            { colId: 'TaxiInTime', field: 'TaxiInTime', headerName: 'Taxi in time (' + (this.useUtc ? 'UTC' : 'airport local') + ')', width: 70, valueFormatter: this.formatters.utcTimeFormatter(), filterType: 'date' },
            { colId: 'BayAssignedInTime', field: 'BayAssignedInTime', headerName: 'Bay assigned in time (' + (this.useUtc ? 'UTC' : 'airport local') + ')', width: 70, valueFormatter: this.formatters.utcTimeFormatter(), filterType: 'date' },
            { colId: 'ParkInTime', field: 'ParkInTime', headerName: 'Park in time (' + (this.useUtc ? 'UTC' : 'airport local') + ')', width: 70, valueFormatter: this.formatters.utcTimeFormatter(), filterType: 'date' },
            { colId: 'IcemanAcceptInTime', field: 'IcemanAcceptInTime', headerName: 'Iceman accept in time (' + (this.useUtc ? 'UTC' : 'airport local') + ')', width: 70, valueFormatter: this.formatters.utcTimeFormatter(), filterType: 'date' },
            { colId: 'IcehouseAcceptInTime', field: 'IcehouseAcceptInTime', headerName: 'Icehouse accept in time (' + (this.useUtc ? 'UTC' : 'airport local') + ')', width: 70, valueFormatter: this.formatters.utcTimeFormatter(), filterType: 'date' },
        );

        columnDefs.push(
            { colId: 'Type1StartTime', field: 'Type1StartTime', headerName: 'Type 1 start time (' + (this.useUtc ? 'UTC' : 'airport local') + ')', width: 70, valueFormatter: this.formatters.utcTimeFormatter(), filterType: 'date' },
            { colId: 'Type1FinishTime', field: 'Type1FinishTime', headerName: 'Type 1 finish time (' + (this.useUtc ? 'UTC' : 'airport local') + ')', width: 70, valueFormatter: this.formatters.utcTimeFormatter(), filterType: 'date' },
            { colId: 'Type4StartTime', field: 'Type4StartTime', headerName: 'Type 4 start time (' + (this.useUtc ? 'UTC' : 'airport local') + ')', width: 70, valueFormatter: this.formatters.utcTimeFormatter(), filterType: 'date' },
            { colId: 'Type4FinishTime', field: 'Type4FinishTime', headerName: 'Type 4 finish time (' + (this.useUtc ? 'UTC' : 'airport local') + ')', width: 70, valueFormatter: this.formatters.utcTimeFormatter(), filterType: 'date' },
        );

        if (this.role == 'deicingRecords') columnDefs.push(
            { colId: 'IcehouseAcceptOutTime', field: 'IcehouseAcceptOutTime', headerName: 'Icehouse accept out time (' + (this.useUtc ? 'UTC' : 'airport local') + ')', width: 70, valueFormatter: this.formatters.utcTimeFormatter(), filterType: 'date' },
            { colId: 'IcemanOutTime', field: 'IcemanOutTime', headerName: 'Iceman accept out time (' + (this.useUtc ? 'UTC' : 'airport local') + ')', width: 70, valueFormatter: this.formatters.utcTimeFormatter(), filterType: 'date' },
            { colId: 'CTMCallTime', field: 'CTMCallTime', headerName: 'CTM call time (' + (this.useUtc ? 'UTC' : 'airport local') + ')', width: 70, valueFormatter: this.formatters.utcTimeFormatter(), filterType: 'date' },
            { colId: 'BayOutTime', field: 'BayOutTime', headerName: 'Bay out time (' + (this.useUtc ? 'UTC' : 'airport local') + ')', width: 70, valueFormatter: this.formatters.utcTimeFormatter(), filterType: 'date' },
            { colId: 'TaxiOutTime', field: 'TaxiOutTime', headerName: 'Taxi out time (' + (this.useUtc ? 'UTC' : 'airport local') + ')', width: 70, valueFormatter: this.formatters.utcTimeFormatter(), filterType: 'date' },
            { colId: 'ScheduledDepartureTime', field: 'ScheduledDepartureTime', headerName: 'Scheduled take off (' + (this.useUtc ? 'UTC' : 'airport local') + ')', width: 70, valueFormatter: this.formatters.utcTimeFormatter(), filterType: 'date' },
        );

        columnDefs.push(
            { colId: 'DeicingDuration', field: 'DeicingTime', headerName: 'Deicing duration (minutes)', width: 90, filterType: 'integer' },

            { colId: 'DeicingFluidName', field: 'DeicingFluidName', headerName: 'Deicing fluid', width: 200 },
            { colId: 'Type1Volume', field: 'Type1Volume', headerName: 'Deicing fluid quantity (' + (this.useGallons ? 'gal' : 'L') + ')', width: 80, filterType: 'float', valueFormatter: this.formatters.volumeFormatter() },
        );

        if (this.role == 'deicingRecords' || this.role == 'airline') columnDefs.push(
            { colId: 'DeicingRecord.Type1MixPercent', field: 'DeicingRecord.Type1MixPercent', headerName: 'Deicing fluid mixture ratio (%)', width: 100, filterType: 'float', valueFormatter: this.formatters.volumeFormatter() },
        );

        columnDefs.push(
            { colId: 'AntiIcingFluidName', field: 'AntiIcingFluidName', headerName: 'Anti-icing fluid', width: 200 },
            { colId: 'Type4Volume', field: 'Type4Volume', headerName: 'Anti-icing quantity (' + (this.useGallons ? 'gal' : 'L') + ')', width: 80, filterType: 'float', valueFormatter: this.formatters.volumeFormatter() },
        );

        if (this.role == 'deicingRecords' || this.role == 'airline') columnDefs.push(
            { colId: 'DeicingRecord.Type4MixPercent', field: 'DeicingRecord.Type4MixPercent', headerName: 'Anti-icing mixture ratio (%)', width: 100, filterType: 'float', valueFormatter: this.formatters.volumeFormatter() },
        );

        columnDefs.push(
            { colId: 'DeicingRecord.AircraftCondition', field: 'DeicingRecord.AircraftCondition', headerName: 'Aircraft condition', width: 100 },
            { colId: 'DeicingRecord.Remarks', field: 'DeicingRecord.Remarks', headerName: 'Remarks', width: 100 },
            { colId: 'DeicingRecord.Sections', field: 'DeicingRecord.Sections', headerName: 'Area(s) treated', width: 100 },
        );

        if (this.role == 'deicingRecords' || this.role == 'flightOps') columnDefs.push(
            { colId: 'DeicingRecord.AircraftSides', field: 'DeicingRecord.AircraftSides', headerName: 'Sides', width: 100 },
            { colId: 'DeicingRecord.Treatment1', field: 'DeicingRecord.Treatment1', headerName: 'Treatment 1', width: 100 },
            { colId: 'DeicingRecord.Treatment2', field: 'DeicingRecord.Treatment2', headerName: 'Treatment 2', width: 100 },
            { colId: 'DeicingRecord.CrewNumber', field: 'DeicingRecord.CrewNumber', headerName: 'Crew number', width: 100 },
            { colId: 'DeicingRecord.ManualMethods', field: 'DeicingRecord.ManualMethods', headerName: 'Manual method(s)', width: 100 },
        );

        columnDefs.push(
            { colId: 'OAT', field: 'OAT', headerName: 'OAT (' + (this.useFahrenheit ? '°F' : '°C') + ')', width: 60, filterType: 'float', isFahrenheit: false, valueFormatter: this.formatters.temperatureFormatter()  },
            { colId: 'DeicingRecord.PrecipitationType', field: 'DeicingRecord.PrecipitationType', headerName: 'Active precipitation type', width: 100 },
            { colId: 'DeicingRecord.Completed', field: 'DeicingRecord.Completed', headerName: "Completed?", width: 70, cellRenderer: yesNo, filterType: 'integer' },
            { colId: 'DeicingRecord.FormatVersion', field: 'DeicingRecord.FormatVersion', headerName: 'App version', width: 120 },
            { colId: 'DeicingRecord.IsHOTDeicing', field: 'DeicingRecord.IsHOTDeicing', headerName: 'HOT required?', width: 80, cellRenderer: yesNo, filterType: 'boolean' },
        );

        if (this.role == 'deicingRecords' || this.role == 'flightOps') columnDefs.push(
            { colId: 'DeicingRecord.Steps', field: 'DeicingRecord.Steps', headerName: 'Steps', width: 90, filterType: 'integer' },
        );
        
        if (this.role == 'deicingRecords') columnDefs.push(
            { colId: 'CertificationTime', field: 'CertificationTime', headerName: 'Certification time (' + (this.useUtc ? 'UTC' : 'airport local') + ')', width: 100, valueFormatter: this.formatters.utcTimeFormatter(), filterType: 'date' },
            { colId: 'Driver', field: 'Driver', headerName: 'Driver', width: 100 },
            { colId: 'Operator', field: 'Operator', headerName: 'Operator', width: 100 },
            {
                colId: 'HasSignature', field: 'HasSignature', headerName: 'Signature', width: 90, searchable: false,
                cellRenderer: c => {
                    var container = document.createElement('div');
                    if (c.value) {
                        container.innerHTML = '<a href onclick="event.preventDefault();" class="mdi mdi-eye"></a>';
                        container.querySelector('a').addEventListener('click',
                            () => {
                                this.api.DeicingRecordSignature.export({ id: c.data.DeicingRecord.Id }).then(response => {
                                    var fr = new FileReader();
                                    fr.onload = () => {
                                        this.dialogService.show(this.viewContainerRef, DeicingRecordSignatureDialogComponent,
                                            {
                                                src: fr.result
                                            },
                                            {
                                                title: 'Certification signature',
                                                width: 640,
                                                height: 320,
                                                modal: true,
                                            });
                                    };
                                    fr.readAsDataURL(response.body);
                                });
                            });
                    }
                    return container;
                }
            },
            { colId: "TruckCount", field: "TruckCount", headerName: "# of trucks", width: 70, filterType: "integer" },
        )
        if (this.role == 'deicingRecords')  columnDefs.push(
            { colId: 'TruckNames', field: 'TruckName', headerName: 'Truck', width: 100, cellRenderer: 'truckCellRenderer', sortable: false },
        );
        
        this.columnDefs = [
            {
                headerName: 'Deicing record',
                children: columnDefs,
            }
        ];
    }

    // show/hide UTC time columns in the grid
    showColumnsInUtc() {
        this.buildColumnDefs();
        this.grid.gridApi.setColumnDefs(this.columnDefs);
        this.storageService.save(this.useUtcStorageKey, this.useUtc ? 'yes' : 'no');

        if (this.grid.gridApi.getDisplayedRowCount() > 0) {
            this.grid.refresh();
        }
    }

    showColumnsInFahrenheit() {
        this.buildColumnDefs();
        this.grid.gridApi.setColumnDefs(this.columnDefs);
        this.storageService.save(this.useFahrenheitStorageKey, this.useFahrenheit ? 'yes' : 'no');

        if (this.grid.gridApi.getDisplayedRowCount() > 0) {
            this.grid.refresh();
        }
    }

    showColumnsInGallons() {
        this.buildColumnDefs();
        this.grid.gridApi.setColumnDefs(this.columnDefs);
        this.storageService.save(this.useGallonsStorageKey, this.useGallons ? 'yes' : 'no');

        if (this.grid.gridApi.getDisplayedRowCount() > 0) {
            this.grid.refresh();
        }
    }

    resetQuery() {
        this.query = {
            AirlineId: [],
            AirportId: [],
            ServiceProviderId: [],
            FromDate: null,
            ToDate: null,
            Filters: []
        };
    }
    
    getQuery() {
        return {
            ...this.query,
            UseGallons: this.useGallons,
            UseFahrenheit: this.useFahrenheit,
            UseUTC: this.useUtc,
        };
    }

    hasRequiredFilters() {
        return Object.keys(this.query).filter((item) => {
            return (Array.isArray(this.query[item]) && this.query[item].length > 0) || this.query[item] !== null;
        }).length > 0;
    }

    refresh() {
        this.storageService.save(this.storeKey, this.query);
        this.grid.refresh();
    }

    deleteSelected() {
        if (confirm(this.translateService.translate('Are you sure?'))) {
            var selectedIds = this.grid.gridApi.getSelectedRows()
                .map(row => row.DeicingRecord ? row.DeicingRecord.Id : null);

            var self = this;
            this.authenticationService.getCurrentUser().then(currentUser => {
                if (currentUser.TicketTrackingRequired) {
                    this.ticketTrackingSystem.getComment(this.viewContainerRef)
                        .then(result => {
                            this.api.DeicingRecordDataUpload.delete(Object.assign(result, { Ids: selectedIds })).then(() => {
                                self.notification.show(self.translateService.translate('The data has been deleted.'));
                                self.refresh();
                            });
                        });
                } else {
                    this.api.DeicingRecordDataUpload.delete({ Ids: selectedIds }).then(() => {
                        self.notification.show(self.translateService.translate('The data has been deleted.'));
                        self.refresh();
                    });
                }
            });
        }
    }

    deicingRecordFormatValueFormatter() {
        return (params: ValueFormatterParams) => params.value && this.deicingRecordFormats[params.value] !== 'undefined'
            ? this.deicingRecordFormats[params.value]
            : params.value;
    }

    downloadAccountingReport() {
        this.api.DeicingRecordSASAccountingReport.export({
            FromDate: this.query.FromDate,
            ToDate: this.query.ToDate,
            AirlineId: this.query.AirlineId,
            AirportId: this.query.AirportId,
            ServiceProviderId: this.query.ServiceProviderId,
        }).then((response) => {
            this.fileDownloadService.download(response.body, header => response.headers.get(header));
        });
    }
}

@Component({
    selector: 'truck-cell',
    template: `
        <a *ngIf="params.data?.IsMobile; else manualEntry" href="javascript:;" (click)='viewTruck(params.data)' class='mdi mdi-eye'></a>
        <ng-template #manualEntry>
            <span>{{params.value}}</span>
        </ng-template>
        `,
    standalone: true,
    imports: [NgIf]
})
export class DeicingRecordTruckCellRendererComponent implements ICellRendererAngularComp {
    public params: any;

    constructor(
        private dialogService: DialogService,
        private viewContainerRef: ViewContainerRef,
        private api: ApiService,
        private $root: RootScope,
    ) {
    }

    agInit(params: any): void {
        this.params = params;
    }

    refresh(): boolean {
        return false;
    }

    viewTruck(row) {
        var quantities = this.api.DeicingRecordTruckQuantity.query({ deicingRecordId: row.DeicingRecord.Id });
        quantities.$promise.then(() => {
	        quantities.forEach(info => {
		        if (info.CertificationTime) {
			        info.CertificationTimeUtc = moment.utc(info.CertificationTime).format(this.$root.timeFormat);
			        if (info.TimeZone) {
				        info.CertificationTimeLocal = moment.utc(info.CertificationTime)['tz'](info.TimeZone).format(this.$root.timeFormat + ' ZZ');
			        }
		        }
	        });

            this.dialogService.show(this.viewContainerRef, DeicingRecordTruckDialogComponent,
                {
                    truckQuantities: quantities,
                    api: this.api,
                    dialogService: this.dialogService,
                },
                {
                    title: 'Truck information',
                    width: 700,
                    height: 320,
                    modal: true,
                    dialogClass: 'flexDialog'
                });
        });
    }
}

@Component({
    template: `
<div class="ui-dialog-content">
    <table class="table" style="width:100%;">
        <tr>
            <th>{{"Truck #"}}</th>
            <th>{{"Driver"}}</th>
            <th>{{"Operator"}}</th>
            <th>{{"Inspection"}}</th>
            <th>{{"Remarks"}}</th>
            <th>{{"Refractometer"}}</th>
            <th>{{"Accuracy Test Completed"}}</th>
            <th>{{"Type 1 Brix"}}</th>
            <th>{{"Type 1 Mix Percent"}}</th>
            <th>{{"Deicing LOUT"}}</th>
            <th>{{"Type 4 Brix"}}</th>
            <th>{{"Type 4 Mix Percent"}}</th>
            <th>{{"Anti-icing LOUT"}}</th>
            <th>{{"Certified by"}}</th>
            <th>{{"Certification time (UTC)"}}</th>
            <th>{{"Certification time (Airport local)"}}</th>
            <th>{{"Signature"}}</th>
        </tr>
        <tr *ngFor="let row of truckQuantities">
            <td>{{row.Truck}}</td>
            <td>{{row.Driver}}</td>
            <td>{{row.Operator}}</td>
            <td>
                <ul style="min-width: 200px;" *ngIf="row.Inspection">
                    <!-- <li *ngFor="let element of row.Inspection.split(',')">{{element}}</li> -->
                    <li *ngFor="let element of row.Inspection">
                        {{element.Name}} : {{element.Status}} 
                    </li>
                </ul>
            </td>
            <td>{{row.Remarks}}</td>
            <td>{{row.Refractometer}}</td>
            <td>{{row.AccuracyTestCompleted}}</td>
            <td>{{row.Type1Brix}}</td>
            <td>{{row.Type1MixPercent}}</td>
            <td>{{row.DeicingLOUT}}</td>
            <td>{{row.Type4Brix}}</td>
            <td>{{row.Type4MixPercent}}</td>
            <td>{{row.AntiIcingLOUT}}</td>
            <td>{{row.CertifiedBy}}</td>
            <td>{{row.CertificationTimeUtc}}</td>
            <td>{{row.CertificationTimeLocal}}</td>
            <td><a *ngIf="row.HasSignature" (click)="showSignature(row)" class="mdi mdi-eye"></a></td>
        </tr>
    </table>
</div>
<div class="ui-dialog-buttonpane ui-widget-content ui-helper-clearfix">
    <small><sup>*</sup>Top-up details available in the inspection form.</small>
    <div class="ui-dialog-buttonset">
        <button (click)="exportSafetyInspectionForm()"><span class="mdi mdi-download"></span> Export Safety Inspection Form</button> 
    </div>
</div>`,
    standalone: true,
    imports: [NgFor, NgIf]
})
export class DeicingRecordTruckDialogComponent implements OnInit {
    @Input('truckQuantities') truckQuantities;

    constructor(
        private api: ApiService,
        private viewContainerRef: ViewContainerRef,
        private dialogService: DialogService,
        private fileDownloadService: FileDownloadService) { }

    ngOnInit(): void {
        // TODO: why are there parsed inspections here?
        // Parse inspection string as JSON
        this.truckQuantities.forEach(tq => {
            try {
                tq.Inspection = JSON.parse(tq.Inspection);
           }
            catch (error) {
                // do nothing if Inspection is already parsed
            }
        });
    }

    showSignature(row) {
	    this.api.DeicingRecordTruckQuantitySignature.export({ id: row.Id }).then(response => {
		    var fr = new FileReader();
		    fr.onload = () => {
			    this.dialogService.show(this.viewContainerRef, DeicingRecordSignatureDialogComponent,
				    {
					    src: fr.result
				    },
				    {
					    title: 'Certification signature',
					    width: 640,
					    height: 320,
					    modal: true,
				    });
		    };
		    fr.readAsDataURL(response.body);
	    });
    }

    // Download a pdf version of the safety inspection form
    exportSafetyInspectionForm() {
        this.api.DeicingVehicleSafetyInspectionForm.export({ ids: [this.truckQuantities[0].InspectionId]}).then(response => {
            this.fileDownloadService.download(response.body, header => response.headers.get(header));
        });
    }
}
