import {Component, ViewChild, OnInit, OnDestroy} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService } from '../shared/Api.service';
import { ChangesService, SwxModule, GridModule } from 'swx.front-end-lib';
import { TicketTrackingSystemService } from '../shared/TicketTrackingSystem.service';
import { TranslateService, ConfirmService, FilterPipe, GridComponent } from 'swx.front-end-lib';
import { HasPermissionService, HasPermissionPipe } from '../shared/HasPermission.pipe';
import { NgIf, NgFor } from '@angular/common';
import { FormsModule } from '@angular/forms';
import {AirlineSelectionDialogComponent} from "./airlineSelectionDialog/AirlineSelectionDialog.component";
import {ServiceProviderSelectionDialogComponent} from "./serviceProviderSelectionDialog/ServiceProviderSelectionDialog.component";
import {Dialog, DialogRef} from "@angular/cdk/dialog";
import {Subscription} from "rxjs";

@Component({
    templateUrl: 'UserDetail.component.html',
    standalone: true,
    imports: [FormsModule, SwxModule, NgIf, NgFor, GridModule, HasPermissionPipe]
})
export class UserDetailComponent implements OnInit, OnDestroy {
    item;
    returnPath;
    permissions = this.api.Permission.query();
    serviceProviders = this.api.ServiceProvider.query();
    airlines = this.api.Airline.query();
    deicingUserLogoSources = this.api.DeicingUserLogoSource.query();
    RepeatPassword;
    @ViewChild('ngForm', { static: true }) ngForm;
    tab;
    deicingServiceProvider;
    serviceProviderSearchQuery;
    deicingAirline;
    airlineSearchQuery;
    serviceProvidersColumnDefs = null;
    @ViewChild('serviceProvidersGrid', { static: false }) serviceProvidersGrid: GridComponent;
    airlinesColumnDefs = null;
    @ViewChild('airlinesGrid', { static: false }) airlinesGrid: GridComponent;
    sureWxDomainRegex = new RegExp('@surewx.com$');

    ticketTrackers = ['Gemini', 'YouTrack'];
    
    serviceProviderSelectionDialogRef: DialogRef<number[], ServiceProviderSelectionDialogComponent>;
    serviceProviderSelectionDialogSub: Subscription;
    
    airlineSelectionDialogRef: DialogRef<number[], AirlineSelectionDialogComponent>;
    airlineSelectionDialogSub: Subscription;

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private api: ApiService,
        private changes: ChangesService,
        private ticketTrackingSystem: TicketTrackingSystemService,
        private translateService: TranslateService,
        private confirmService: ConfirmService,
        public hasPermissionService: HasPermissionService,
        private dialog: Dialog,
    ) {
    }

    ngOnInit() {
        this.returnPath = this.route.snapshot.url[0].path.replace('/:id', '');
        const id = this.route.snapshot.params['id'];
        const copyId = this.route.snapshot.queryParams['copy'];
        const isNew = id === 'new';

        if (copyId) {
            this.item = this.api.User.get({ id: copyId });
            this.item.$promise.then(() => {
                delete this.item.Id;
                delete this.item.Name;
                delete this.item.Username;
                delete this.item.Email;
                delete this.item.Password;
                delete this.RepeatPassword;
                delete this.item.Comments;
                delete this.item.TicketTrackingUserId;
                delete this.item.TicketTrackingUsername;
                delete this.item.TicketTrackingApiKey;

                if (this.item.DeicingUserDeicingServiceProviders) {
                    this.item.DeicingUserDeicingServiceProviders.forEach(related => {
                        delete related.DeicingUserId;
                    });
                }

                if (this.item.DeicingUserDeicingAirlines) {
                    this.item.DeicingUserDeicingAirlines.forEach(related => {
                        delete related.DeicingUserId;
                    });
                }
            });
            this.RepeatPassword = '__PASSWORD__';
        } else if (isNew) {
            this.item = this.api.User.create({
                AirlineId: null,
                Active: true,
                LoginRequired: true
            });
        } else {
            this.item = this.api.User.get({ id: id });
            this.RepeatPassword = '__PASSWORD__';
        }

        Promise.all([
            this.item.$promise,
            this.airlines.$promise,
            this.serviceProviders.$promise
        ]).then(() => {
            this.item.DeicingUserDeicingServiceProviders = this.item.DeicingUserDeicingServiceProviders || [];
            this.item.DeicingUserDeicingAirlines = this.item.DeicingUserDeicingAirlines || [];

            this.airlinesColumnDefs = [
                { colId: "DeicingAirlineId", field: "DeicingAirlineId", headerName: "#", width: 60, filterType: "integer" },
                { colId: "Name", field: "Name", headerName: "Name", width: 300, cellRenderer: c => {
                    if (c.data == null) return '';
                    var container = document.createElement('div');
                    if (this.hasPermissionService.hasPermission('ConfigViewAirlines')) {
                        container.innerHTML += '<a href="/airlines/' + c.data.DeicingAirlineId + '" onclick="event.preventDefault();">' + c.value + '</a>';
                        container.querySelector('a').addEventListener('click',
                            e => {
                                if (this.ngForm.form.pristine) {
                                    this.router.navigateByUrl(e.target['getAttribute']('href'));
                                } else {
                                    this.confirmService.confirm(this.translateService.translate('Are you sure you want to discard your changes?')).then(() => {
                                        this.router.navigateByUrl(e.target['getAttribute']('href'));
                                    }).catch(() => {});
                                }
                            });
                    } else {
                        container.innerHTML = c.value;
                    }
                    return container;
                } },
                { colId: "IATACode", field: "IATACode", headerName: "IATA code", width: 120 },
                { colId: "ICAOCode", field: "ICAOCode", headerName: "ICAO code", width: 120 },
	            { colId: "_spacer", "flex": 1, excelIgnore: true },
                { colId: "Actions", field: "DeicingAirlineId", headerName: "", width: 50, cellClass: "actions", cellRenderer: c => {
                    if (c.data == null) return '';
                    var container = document.createElement('div');
                    if (this.hasPermissionService.hasPermission('ConfigEditUsers')) {
                        container.innerHTML += '<a class="mdi mdi-delete" title="' + this.translateService.translate('Delete') + '" onclick="event.preventDefault();"></a>';
                        container.querySelector('.mdi-delete').addEventListener('click', () => this.removeDeicingUserDeicingAirline(c.data).then(() => c.api.setRowData(this.transformDeicingUserAirlines(this.item.DeicingUserDeicingAirlines))));
                    }
                    return container;
                }, pinned: "right" },
            ];

            this.serviceProvidersColumnDefs = [
                { colId: "DeicingServiceProviderId", field: "DeicingServiceProviderId", headerName: "#", width: 60, filterType: "integer" },
                { colId: "Name", field: "Name", headerName: "Name", width: 300, cellRenderer: c => {
                    if (c.data == null) return '';
                    var container = document.createElement('div');
                    if (this.hasPermissionService.hasPermission('ConfigViewServiceProviders')) {
                        container.innerHTML += '<a href="/serviceProviders/' + c.data.DeicingServiceProviderId + '" onclick="event.preventDefault();">' + c.value + '</a>';
                        container.querySelector('a').addEventListener('click',
                            e => {
                                if (this.ngForm.form.pristine) {
                                    this.router.navigateByUrl(e.target['getAttribute']('href'));
                                } else {
                                    this.confirmService.confirm(this.translateService.translate('Are you sure you want to discard your changes?')).then(() => {
                                        this.router.navigateByUrl(e.target['getAttribute']('href'));
                                    }).catch(() => {});
                                }
                            });
                    } else {
                        container.innerHTML = c.value;
                    }
                    return container;
                } },
                { colId: "Code", field: "Code", headerName: "Upload mapping code", width: 120 },
                { colId: "AirportCode", field: "AirportCode", headerName: "Airport", width: 150 },
	            { colId: "Spacer", field: "Id", headerName: "", cellRenderer: () => "", "flex": 1 },
                { colId: "Actions", field: "DeicingServiceProviderId", headerName: "", width: 50, cellClass: "actions", cellRenderer: c => {
                    if (c.data == null) return '';
                    var container = document.createElement('div');
                    if (this.hasPermissionService.hasPermission('ConfigEditUsers')) {
                        container.innerHTML += '<a class="mdi mdi-delete" title="' + this.translateService.translate('Delete') + '" onclick="event.preventDefault();"></a>';
                        container.querySelector('.mdi-delete').addEventListener('click', () => this.removeDeicingUserDeicingServiceProvider(c.data).then(() => c.api.setRowData(this.transformDeicingUserServiceProviders(this.item.DeicingUserDeicingServiceProviders))));
                    }
                    return container;
                }, pinned: "right" },
            ];

            setTimeout(() => {
                this.ngForm.form.controls.airlinesForm.updateValueAndValidity();
                this.ngForm.form.controls.serviceProvidersForm.updateValueAndValidity();
            });
        });

        this.tab = location.hash ? location.hash.substring(1) : 'basicInfo';
    }
    
    transformDeicingUserServiceProviders(items) {
        if (items == null) return items;
        items.forEach(x => {
            var serviceProvider = this.serviceProviders.find(a => a.Id === x.DeicingServiceProviderId) || {};
            x.Name = serviceProvider.Name;
            x.Code = serviceProvider.Code;
            x.AirportCode = serviceProvider.AirportCode;
        });

        return items;
    }

    transformDeicingUserAirlines(items) {
        if (items == null) return items;
        items.forEach(x => {
            var airline = this.airlines.find(a => a.Id === x.DeicingAirlineId);
            x.Name = airline.Name;
            x.IATACode = airline.IATACode;
            x.ICAOCode = airline.ICAOCode;
        });

        return items;
    }

    filter = (items, searchQuery) => FilterPipe.instance.transform(items, searchQuery);

    save() {
        this.ticketTrackingSystem.trackAndSave(this.item, this.returnPath);
    }

    cancel() {
        this.router.navigateByUrl(this.returnPath);
    }

    viewHistory() {
        this.changes.show({
            SubjectType: ['User'],
            SubjectId: this.item.Id
        });
    };

    switchTab(tab) {
        location.hash = tab;
        this.tab = tab;
    };

    isPermissionEnabled(option) {
        const selected = (this.item.Permissions || '').split(', ');
        return selected.indexOf(option) > -1;
    };

    togglePermission(option) {
        const selected = this.item.Permissions ? this.item.Permissions.split(', ') : [];
        const index = selected.indexOf(option);
        if (index > -1) {
            selected.splice(index, 1);
        } else {
            selected.push(option);
        }
        this.item.Permissions = selected.join(', ');
        this.ngForm.form.markAsDirty();
    }

    serviceProvidersNotEnabled(deicingServiceProviders: any[], deicingUserDeicingServiceProviders: any[]) {
        if (!deicingServiceProviders || !deicingUserDeicingServiceProviders) return deicingServiceProviders;

        return deicingServiceProviders.filter(a => deicingUserDeicingServiceProviders.every(r => r.DeicingServiceProviderId !== a.Id as any));
    }
    
    showServiceProvidersPopup() {
         this.serviceProviderSelectionDialogRef = this.dialog.open(ServiceProviderSelectionDialogComponent,
            {
                data: {
                    title: 'Select service providers',
                    options: this.serviceProvidersNotEnabled(this.serviceProviders, this.item.DeicingUserDeicingServiceProviders),
                },
                width: '80%',
                height: (window.innerHeight * 0.8) + 'px',
            });
        this.serviceProviderSelectionDialogSub = this.serviceProviderSelectionDialogRef.closed
            .subscribe(selectedIds => {
                if (selectedIds) {
                    this.item.DeicingUserDeicingServiceProviders = this.item.DeicingUserDeicingServiceProviders || [];
                    selectedIds.forEach(id => this.item.DeicingUserDeicingServiceProviders.push({
                        DeicingServiceProviderId: id
                    }));
                    this.serviceProvidersGrid.gridApi.setRowData(this.transformDeicingUserServiceProviders(this.item.DeicingUserDeicingServiceProviders));
                    this.ngForm.form.controls.serviceProvidersForm.updateValueAndValidity();
                    this.ngForm.form.controls.serviceProvidersForm.markAsDirty();
                }
            })
    }

    removeDeicingUserDeicingServiceProvider(item) {
        return this.confirmService.confirm(this.translateService.translate('Are you sure?')).then(() => {
            this.item.DeicingUserDeicingServiceProviders.splice(this.item.DeicingUserDeicingServiceProviders.indexOf(item), 1);

            if (item.DeicingServiceProviderId === this.item.PrimaryDeicingServiceProviderId) {
                this.item.PrimaryDeicingServiceProviderId = null;
            }

            this.ngForm.form.controls.serviceProvidersForm.updateValueAndValidity();
            this.ngForm.form.controls.serviceProvidersForm.markAsDirty();
        }).catch(() => {});
    }

    airlinesNotEnabled(deicingAirlines: any[], deicingUserDeicingAirlines: any[]) {
        if (!deicingAirlines || !deicingUserDeicingAirlines) return deicingAirlines;

        return deicingAirlines.filter(a => deicingUserDeicingAirlines.every(r => r.DeicingAirlineId !== a.Id as any));
    }

    showAirlinesPopup() {
        this.airlineSelectionDialogRef = this.dialog.open(AirlineSelectionDialogComponent,
            {
                data: {
                    title: 'Select airlines',
                    options: this.airlinesNotEnabled(this.airlines, this.item.DeicingUserDeicingAirlines),
                },
                width: '80%',
                height: (window.innerHeight * 0.8) + 'px',
            });
        
        this.airlineSelectionDialogSub = this.airlineSelectionDialogRef.closed.subscribe(selectedIds => {
            if (selectedIds) {
                this.item.DeicingUserDeicingAirlines = this.item.DeicingUserDeicingAirlines || [];
                selectedIds.forEach(id => this.item.DeicingUserDeicingAirlines.push({
                    DeicingAirlineId: id
                }));
                this.airlinesGrid.gridApi.setRowData(this.transformDeicingUserAirlines(this.item.DeicingUserDeicingAirlines));
                this.ngForm.form.controls.airlinesForm.updateValueAndValidity();
                this.ngForm.form.controls.airlinesForm.markAsDirty();
            }
        })
    }

    removeDeicingUserDeicingAirline(item) {
        return this.confirmService.confirm(this.translateService.translate('Are you sure?')).then(() => {
            this.item.DeicingUserDeicingAirlines.splice(this.item.DeicingUserDeicingAirlines.indexOf(item), 1);
            this.ngForm.form.controls.airlinesForm.updateValueAndValidity();
            this.ngForm.form.controls.airlinesForm.markAsDirty();
        }).catch(() => {});
    }
    
    ngOnDestroy() {
        if (this.serviceProviderSelectionDialogSub) {
            this.serviceProviderSelectionDialogSub.unsubscribe();
        }
        
        if (this.airlineSelectionDialogSub) {
            this.airlineSelectionDialogSub.unsubscribe();
        }
    }
}




