import { Component } from '@angular/core';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { ApiService } from '../shared/Api.service';
import { FormsModule } from '@angular/forms';
import { NgIf } from '@angular/common';
import { SwxModule } from 'swx.front-end-lib';

@Component({
    templateUrl: 'ResetPassword.component.html',
    standalone: true,
    imports: [SwxModule, NgIf, RouterLink, FormsModule]
})
export class ResetPasswordComponent {
    token: string;
    password: string;
    repeatPassword: string;
    sent = false;
    error = false;

    constructor(
        private route: ActivatedRoute,
        private api: ApiService
    ) {
        this.token = this.route.snapshot.params['id'];

        this.api.PasswordResetToken.get(this.token).$promise.then(result => {
            this.error = !result;
        });
    }

    submit() {
        this.api.ResetPassword.post({
                Token: this.token,
                Password: this.password
            })
            .then(result => {
                    if (result) {
                        this.sent = true;
                    } else {
                        this.error = true;
                    }
                },
                () => {
                    this.error = true;
                }
            );
    }
}