import {Component, Inject, OnInit} from "@angular/core";
import {NgFor, NgIf} from "@angular/common";
import {ApiService} from "../../shared/Api.service";
import {DialogComponent, DialogContentComponent, DialogHeaderComponent, FileDownloadService} from "swx.front-end-lib";
import {DeicingRecordSignatureDialogComponent} from "../../deicingRecordSignatureDialog/DeicingRecordSignatureDialog.component";
import {Dialog, DIALOG_DATA} from "@angular/cdk/dialog";

@Component({
    templateUrl: 'DeicingRecordTruckDialog.component.html',
    standalone: true,
    imports: [NgFor, NgIf, DialogComponent, DialogHeaderComponent, DialogContentComponent]
})
export class DeicingRecordTruckDialogComponent implements OnInit {
   truckQuantities!: any;

    constructor(
        @Inject(DIALOG_DATA) private data: { truckQuantities: any },
        private api: ApiService,
        private dialog: Dialog,
        private fileDownloadService: FileDownloadService) { }

    ngOnInit(): void {
        this.truckQuantities = this.data.truckQuantities;
        
        // Parse inspection string as JSON
        this.truckQuantities.forEach(tq => {
            try {
                tq.Inspection = JSON.parse(tq.Inspection);
            }
            catch (error) {
                // do nothing if Inspection is already parsed
            }
        });
    }

    showSignature(row) {
        this.api.DeicingRecordTruckQuantitySignature.export({ id: row.Id }).then(response => {
            const fr = new FileReader();
            fr.onload = () => {
                this.dialog.open(DeicingRecordSignatureDialogComponent,
                    {
                        data: {
                            src: fr.result
                        },
                        width: '640px',
                        height: '320px',
                        
                    });
            };
            fr.readAsDataURL(response.body);
        });
    }

    // Download a pdf version of the safety inspection form
    exportSafetyInspectionForm() {
        this.api.DeicingVehicleSafetyInspectionForm.export({ ids: [this.truckQuantities[0].InspectionId]}).then(response => {
            this.fileDownloadService.download(response.body, header => response.headers.get(header));
        });
    }
}
