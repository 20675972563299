import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { FileDownloadService } from 'swx.front-end-lib';
import { NgIf } from '@angular/common';

@Component({
    template: `
        <a *ngIf="fileName" (click)="viewData(params)">{{fileName}}</a>`,
    standalone: true,
    imports: [NgIf]
})
export class FileCellRendererComponent implements ICellRendererAngularComp {
    public params: any;
    public fileName: string = '';

    constructor(
        private fileDownloadService: FileDownloadService,
    ) {
    }

    agInit(params: any): void {
        this.params = params;
        this.fileName = params.data?.Filename;
    }

    refresh(): boolean {
        return false;
    }

    viewData({ data }) {
        const rawData = data.Data;

        const bytes = atob(rawData);
        const buffer = new ArrayBuffer(bytes.length);
        let byteArray = new Uint8Array(buffer);
        for (let i = 0; i < bytes.length; i++) {
            byteArray[i] = bytes.charCodeAt(i);
        }

        const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        this.fileDownloadService.download(blob, this.fileName);
    }
}
