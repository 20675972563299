import {Component} from "@angular/core";
import {NgIf} from "@angular/common";
import {ICellRendererAngularComp} from "ag-grid-angular";
import {ApiService} from "../../shared/Api.service";
import {RootScope} from "../../shared/RootScope.service";
import moment from "moment/moment";
import {DeicingRecordTruckDialogComponent} from "../deicingRecordTruckDialog/DeicingRecordTruckDialog.component";
import {Dialog} from "@angular/cdk/dialog";

@Component({
    selector: 'truck-cell',
    template: `
        <a *ngIf="params.data?.IsMobile; else manualEntry" href="javascript:;" (click)='viewTruck(params.data)' class='mdi mdi-eye'></a>
        <ng-template #manualEntry>
            <span>{{params.value}}</span>
        </ng-template>
        `,
    standalone: true,
    imports: [NgIf]
})
export class DeicingRecordTruckCellRendererComponent implements ICellRendererAngularComp {
    public params: any;

    constructor(
        private dialog: Dialog,
        private api: ApiService,
        private $root: RootScope,
    ) {
    }

    agInit(params: any): void {
        this.params = params;
    }

    refresh(): boolean {
        return false;
    }

    viewTruck(row) {
        const quantities = this.api.DeicingRecordTruckQuantity.query({ deicingRecordId: row.DeicingRecord.Id });
        quantities.$promise.then(() => {
            quantities.forEach(info => {
                if (info.CertificationTime) {
                    info.CertificationTimeUtc = moment.utc(info.CertificationTime).format(this.$root.timeFormat);
                    if (info.TimeZone) {
                        info.CertificationTimeLocal = moment.utc(info.CertificationTime)['tz'](info.TimeZone).format(this.$root.timeFormat + ' ZZ');
                    }
                }
            });

            this.dialog.open(DeicingRecordTruckDialogComponent,
                {
                    data: {
                        truckQuantities: quantities,
                    },
                    width: '700px',
                    height: '320px',
                });
        });
    }
}
