import {Component, Inject, OnInit, ViewChild} from "@angular/core";
import {FormsModule} from "@angular/forms";
import {DialogComponent, DialogContentComponent, DialogHeaderComponent, FilterPipe, GridComponent, GridModule, SwxModule} from "swx.front-end-lib";
import {DIALOG_DATA, DialogRef} from "@angular/cdk/dialog";

@Component({
    templateUrl: 'ServiceProviderSelectionDialog.component.html',
    standalone: true,
    imports: [FormsModule, GridModule, SwxModule, DialogComponent, DialogHeaderComponent, DialogContentComponent]
})
export class ServiceProviderSelectionDialogComponent implements OnInit {
    title!: string;
    options;
    searchQuery;
    @ViewChild('grid', { static: false }) grid : GridComponent;
    columnDefs = [
        { colId: "Id", field: "Id", headerName: "#", width: 60, filterType: "integer", "checkboxSelection": true },
        { colId: "Name", field: "Name", headerName: "Name", width: 300 },
        { colId: "AirportCode", field: "AirportCode", headerName: "Airport", width: 150 },
    ];
    
    constructor(@Inject(DIALOG_DATA) private data,
                private dialogRef: DialogRef) {
    }
    
    ngOnInit() {
        this.title = this.data.title;
        this.options = this.data.options;
    }

    filter = (items, searchQuery) => FilterPipe.instance.transform(items, searchQuery);

    addSelected() {
        const selectedIds = this.grid.gridApi.getSelectedRows()
            .map(row => row.Id);

        this.dialogRef.close(selectedIds);
    }

    cancel() {
        this.dialogRef.close();
    }
}
