<swx-dialog>
    <swx-dialog-header>Comments</swx-dialog-header>
    <swx-dialog-content>
        <form class="form" #form #ngForm="ngForm" (submit)="save()">
            <fieldset class="contentMargins">
                <label>
                    <span class="caption">{{'Issue Id'|translate}}</span>
                    <autocomplete required [(ngModel)]="ticketNumber" [autocompleteOptions]="autocompleteOptions" name="ticketNumber"></autocomplete>
                </label>
                <label>
                    <span class="caption">{{'Comments'|translate}}</span>
                    <textarea required [(ngModel)]="comments" name="comments" style="width: 400px; height: 75px;"></textarea>
                </label>
                <label>
                    <input type="submit" (click)="save()" [disabled]="!ngForm.form.valid" value="{{'Save'|translate}}" />
                    <input type="button" (click)="cancel()" value="{{'Cancel'|translate}}" />
                </label>
            </fieldset>
        </form>
    </swx-dialog-content>
</swx-dialog>
