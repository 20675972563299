import {Component, Inject, OnInit} from "@angular/core";
import {JsonPipe} from "@angular/common";
import {DialogComponent, DialogContentComponent, DialogHeaderComponent} from "swx.front-end-lib";
import {DIALOG_DATA} from "@angular/cdk/dialog";

@Component({
    templateUrl: './RawDataViewDialog.component.html',
    standalone: true,
    imports: [JsonPipe, DialogComponent, DialogHeaderComponent, DialogContentComponent]
})
export class RawDataViewDialogComponent implements OnInit {
    jsonData!: any;

    constructor(@Inject(DIALOG_DATA) private data: {jsonData: string}) {
    }
    
    ngOnInit() {
        this.jsonData = JSON.parse(this.data.jsonData);
    }
}
