import {Injectable} from '@angular/core'
import { IResourceItem, AuthenticationService, TranslateService, NotificationService } from 'swx.front-end-lib';
import { Router } from '@angular/router';
import { ApiService } from '../shared/Api.service';
import {lastValueFrom} from "rxjs";
import {Dialog} from "@angular/cdk/dialog";
import {TicketTrackingCommentDialogComponent} from "./ticketTrackingCommentDialog/TicketTrackingCommentDialog.component";

@Injectable()
export class TicketTrackingSystemService {
    constructor(
        private dialog: Dialog,
        private authentication: AuthenticationService,
        private router: Router,
        private translateService: TranslateService,
        private notification: NotificationService,
        private api: ApiService,
    ) {
    }

    getComment(): Promise<TicketTrackingCommentInfo> {
        return lastValueFrom(this.dialog.open<TicketTrackingCommentInfo>(TicketTrackingCommentDialogComponent, {
            width: '435px',
        }).closed);
    }

    trackAndSave(item: IResourceItem, returnPath: string) {
        return this.authentication.getCurrentUser().then(currentUser => {
            if (currentUser.TicketTrackingRequired) {
                return this.getComment()
                    .then(result => {
                        return item.$save({}, {
                            comment: result.Comment,
                            ticketNumber: result.TicketNumber,
                        }).then(saveResult => {
                            this.notification.show(this.translateService.translate('The changes have been saved and the ticket comment has been added.'));
                            this.router.navigateByUrl(returnPath);
                            return saveResult;
                        });
                    });
            } else {
                return item.$save().then(saveResult => {
                    this.notification.show(this.translateService.translate('The changes have been saved.'));
                    this.router.navigateByUrl(returnPath);
                    return saveResult;
                });
            }
        });
        
    }

    trackAndDelete(item: IResourceItem, callback: () => any) {
        return this.authentication.getCurrentUser().then(currentUser => {
            if (currentUser.TicketTrackingRequired) {
                return this.getComment()
                    .then(result => {
                        return item.$delete({}, {
                            comment: result.Comment,
                            ticketNumber: result.TicketNumber,
                        }).then(deleteResult => {
                            this.notification.show(this.translateService.translate('The item has been deleted and the ticket comment has been added.'));
                            callback();
                            return(deleteResult);
                        });
                    });
            } else {
                return item.$delete().then(deleteResult => {
                    this.notification.show(this.translateService.translate('The item has been deleted.'));
                    callback();
                    return(deleteResult);
                });
            }
        });
    }

    getIssues(searchTerm): Promise<Issue[]> {
        return this.api.TicketTrackerIssuesController.query({ searchText: searchTerm }).$promise as Promise<Issue[]>;
    }
}

interface Issue {
    Id: string;
    Description: string;
}



class TicketTrackingCommentInfo {
    TicketNumber: string;
    Comment: string;
}
