import { Component, Input } from "@angular/core";
import { SwxModule } from "swx.front-end-lib";
import { NgFor } from "@angular/common";
import { FormsModule } from "@angular/forms";

@Component({
    templateUrl: 'DeicingFormFieldOptionsDialog.component.html',
    standalone: true,
    imports: [FormsModule, NgFor, SwxModule]
})
export class DeicingFormFieldOptionsDialogComponent {
    @Input('field') field;
    @Input('parentForm') parentForm;
    newOption;
}