import { Component, Input } from '@angular/core';
import { CoordinatorClientAirport } from '../shared/Api.service';
import { NgIf, NgSwitch, NgSwitchCase, NgSwitchDefault } from '@angular/common';

@Component({
    selector: 'weather-icon',
    templateUrl: 'WeatherIcon.component.html',
    standalone: true,
    imports: [NgIf, NgSwitch, NgSwitchCase, NgSwitchDefault]
})
export class WeatherIconComponent {
    @Input('airport') airport: CoordinatorClientAirport;
}