import {Component, OnInit, ViewContainerRef} from '@angular/core';
import { ApiService } from '../shared/Api.service';
import { TranslateService, NotificationService, AuthenticationService, SwxModule } from 'swx.front-end-lib';
import { TicketTrackingSystemService } from '../shared/TicketTrackingSystem.service';
import { NgFor, NgIf } from '@angular/common';
import { FormsModule } from '@angular/forms';

@Component({
    templateUrl: 'DeicingRecordDataUpload.component.html',
    standalone: true,
    imports: [FormsModule, SwxModule, NgFor, NgIf]
})
export class DeicingRecordDataUploadComponent implements OnInit {
    item;
    returnPath;
    airports = this.api.Airport.query();
    airlines = this.api.Airline.query();

    matchIdentifierFields = {
        "FlightNumber": "Use flight number column",
        "RegistrationNumber": "Use registration/tail number column",
    };
    deicingRecordTimeZones = this.api.DeicingRecordTimeZone.query();
    serviceProviders = this.api.ServiceProvider.query();
    datePickerOptions: any;
    query: any = {
        ServiceProviderId: -1,
        AirlineId: -1,
        AirportId: null,
        Format: "Standard",
        MatchIdentifierField: "FlightNumber",
        ConsolidateMultipleRowsForSameFlight: false
    };
    result = null;
    
    constructor(
        private api: ApiService,
        private viewContainerRef: ViewContainerRef,
        private translateService: TranslateService,
        private notification: NotificationService,
        private ticketTrackingSystem: TicketTrackingSystemService,
        private authenticationService: AuthenticationService,
    ) {
    }

    ngOnInit(): void {
        console.log('onInit');
        this.datePickerOptions = {
            maxDate: new Date(new Date().getUTCFullYear(), new Date().getUTCMonth(), new Date().getUTCDate(), new Date().getUTCHours(), new Date().getUTCMinutes(), new Date().getUTCSeconds()),
        };

        Promise.all([this.serviceProviders.$promise, this.airlines.$promise])
            .then(() => {
                if (this.serviceProviders.length === 1) {
                    this.query.ServiceProviderId = this.serviceProviders[0].Id;
                }
                if (this.airlines.length === 1) this.query.AirlineId = this.airlines[0].Id;
            });
    }

    upload() {
        this.authenticationService.getCurrentUser().then(currentUser => {
            if (currentUser.TicketTrackingRequired) {
                this.ticketTrackingSystem.getComment(this.viewContainerRef)
                    .then(result => {
                        this.api.DeicingRecordDataUpload.post(Object.assign(this.query, {}, result)).then(result => {
                            this.result = result;
                        });
                    });
            } else {
                this.api.DeicingRecordDataUpload.post(this.query).then(result => {
                    this.result = result;
                });
            }
        });
    };

    simulate() {
        this.api.DeicingRecordDataUpload.post(Object.assign({
            Simulate: true
        }, this.query)).then(result => {
            this.result = result;
        });
    };

    delete() {
        if (confirm(this.translateService.translate('Are you sure?'))) {
            const params = {
                ServiceProviderId: this.query.ServiceProviderId,
                AirlineId: this.query.AirlineId,
                AirportId: this.query.AirportId,
                FromDate: this.query.FromDate,
                ToDate: this.query.ToDate
            };

            this.authenticationService.getCurrentUser().then(currentUser => {
                if (currentUser.TicketTrackingRequired) {
                    this.ticketTrackingSystem.getComment(this.viewContainerRef)
                        .then(result => {
                            this.api.DeicingRecordDataUpload.delete(Object.assign(result, params)).then(() => {
                                this.notification.show(this.translateService.translate('The data has been deleted.'));
                            });
                        });
                } else {
                    this.api.DeicingRecordDataUpload.delete(params).then(() => {
                        this.notification.show(this.translateService.translate('The data has been deleted.'));
                    });
                }
            }); 
        }
    };

    get uploadFormats() {
        const providerId = this.query.ServiceProviderId;
        let flags = ["Standard"];

        if (providerId && providerId !== -1) {
            let provider = this.serviceProviders.find(sp => sp.Id === providerId);
            const providerFlags = provider.DeicingRecordUploadFormatFlags;

            if (providerFlags) {
                flags = providerFlags.split(',');
            }
        }

        return flags;
    }
}
