import { Component } from '@angular/core';
import { AuthenticationService, SwxModule } from 'swx.front-end-lib';
import { HasPermissionPipe } from '../shared/HasPermission.pipe';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { NgIf } from '@angular/common';

@Component({
    selector: '[menu]',
    templateUrl: 'Menu.component.html',
    standalone: true,
    imports: [NgIf, RouterLink, RouterLinkActive, SwxModule, HasPermissionPipe]
})
export class MenuComponent {
    currentUser;

    constructor(
        private authentication: AuthenticationService,
    ) {
        this.authentication.getCurrentUser()
            .then(user => this.currentUser = user);

        this.authentication.userChange.subscribe(user => {
            this.currentUser = user;
        });
    }
}
