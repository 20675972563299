<swx-dialog>
    <swx-dialog-header>{{title}}</swx-dialog-header>
    <swx-dialog-content>
        <div class="options contentMargins">
            <label class="inline search">
                <span class="mdi mdi-magnify"></span>
                <input [(ngModel)]="searchQuery" [ngModelOptions]="{standalone: true}" name="searchQuery" (ngModelChange)="grid.gridApi.setRowData(filter(options, searchQuery));" />
            </label>
            <label class="inline">
                <input type="button" class="green" (click)="addSelected();" value="{{'Add selected service providers'|translate}}" />
            </label>
            <label class="inline">
                <input type="button" (click)="cancel()" value="{{'Cancel'|translate}}" />
            </label>
        </div>
        <config-grid #grid
                     [columnDefs]="columnDefs"
                     [rowSelection]="'multiple'"
                     [rowData]="options">
        </config-grid>
    </swx-dialog-content>
</swx-dialog>
