<swx-dialog>
    <swx-dialog-header>Truck Information</swx-dialog-header>
    <swx-dialog-content>
        <div class="ui-dialog-content">
            <table class="table" style="width:100%;">
                <tr>
                    <th>{{"Truck #"}}</th>
                    <th>{{"Driver"}}</th>
                    <th>{{"Operator"}}</th>
                    <th>{{"Inspection"}}</th>
                    <th>{{"Remarks"}}</th>
                    <th>{{"Refractometer"}}</th>
                    <th>{{"Accuracy Test Completed"}}</th>
                    <th>{{"Type 1 Brix"}}</th>
                    <th>{{"Type 1 Mix Percent"}}</th>
                    <th>{{"Deicing LOUT"}}</th>
                    <th>{{"Type 4 Brix"}}</th>
                    <th>{{"Type 4 Mix Percent"}}</th>
                    <th>{{"Anti-icing LOUT"}}</th>
                    <th>{{"Certified by"}}</th>
                    <th>{{"Certification time (UTC)"}}</th>
                    <th>{{"Certification time (Airport local)"}}</th>
                    <th>{{"Signature"}}</th>
                </tr>
                <tr *ngFor="let row of truckQuantities">
                    <td>{{row.Truck}}</td>
                    <td>{{row.Driver}}</td>
                    <td>{{row.Operator}}</td>
                    <td>
                        <ul style="min-width: 200px;" *ngIf="row.Inspection">
                            <!-- <li *ngFor="let element of row.Inspection.split(',')">{{element}}</li> -->
                            <li *ngFor="let element of row.Inspection">
                                {{element.Name}} : {{element.Status}}
                            </li>
                        </ul>
                    </td>
                    <td>{{row.Remarks}}</td>
                    <td>{{row.Refractometer}}</td>
                    <td>{{row.AccuracyTestCompleted}}</td>
                    <td>{{row.Type1Brix}}</td>
                    <td>{{row.Type1MixPercent}}</td>
                    <td>{{row.DeicingLOUT}}</td>
                    <td>{{row.Type4Brix}}</td>
                    <td>{{row.Type4MixPercent}}</td>
                    <td>{{row.AntiIcingLOUT}}</td>
                    <td>{{row.CertifiedBy}}</td>
                    <td>{{row.CertificationTimeUtc}}</td>
                    <td>{{row.CertificationTimeLocal}}</td>
                    <td><a *ngIf="row.HasSignature" (click)="showSignature(row)" class="mdi mdi-eye"></a></td>
                </tr>
            </table>
        </div>
        <div class="ui-dialog-buttonpane ui-widget-content ui-helper-clearfix">
            <small><sup>*</sup>Top-up details available in the inspection form.</small>
            <div class="ui-dialog-buttonset">
                <button (click)="exportSafetyInspectionForm()"><span class="mdi mdi-download"></span> Export Safety Inspection Form</button>
            </div>
        </div>
    </swx-dialog-content>
</swx-dialog>

