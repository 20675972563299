import { Component } from '@angular/core';
import { ApiService } from '../shared/Api.service';
import { AuthenticationService, NotificationService, TranslateService, SwxModule } from 'swx.front-end-lib';
import { RootScope } from '../shared/RootScope.service';
import { HasPermissionPipe } from '../shared/HasPermission.pipe';
import { FormsModule } from '@angular/forms';
import { NgIf } from '@angular/common';

@Component({
    templateUrl: 'UserProfile.component.html',
    standalone: true,
    imports: [SwxModule, NgIf, FormsModule, HasPermissionPipe]
})
export class UserProfileComponent {
    currentPassword: string;
    newPassword: string;
    repeatPassword: string;
    currentUser;

    constructor(
        private api: ApiService,
        public $root: RootScope,
        private authentication: AuthenticationService,
        private translateService: TranslateService,
        private notification: NotificationService,
    ) {
        this.authentication.getCurrentUser().then(user => {
            this.currentUser = user;
        });
    }

    savePreferences() {
        this.api.User.put('me', {
            UseIataCode: this.currentUser.UseIataCode,
            UseLocalTime: this.currentUser.UseLocalTime,
            UseFahrenheit: this.currentUser.UseFahrenheit
        }).then(result => {
            if (result.Success) {
                this.notification.show(this.translateService.translate('Your preferences have been updated.'));
            }
        },
        () => {
        });
    };

    changePassword() {
        this.api.User.put('me', {
                CurrentPassword: this.currentPassword,
                NewPassword: this.newPassword
            }).then(result => {
                if (result.Success) {
                    this.authentication.setCredentials(this.currentUser.UserEmail, this.newPassword);
                    this.notification.show(this.translateService.translate('Your password has been changed.'));
                } else {
                    this.notification.show('Sorry, that login/password does not match our records.', { type: 'error', sticky: true });
                }
            },
            () => {
                this.notification.show('Sorry, that login/password does not match our records.', { type: 'error', sticky: true });
            });
    };
}
