import {Component, ViewChild, ViewContainerRef} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService } from '../shared/Api.service';
import { ChangesService, SwxModule } from 'swx.front-end-lib';
import { TicketTrackingSystemService } from '../shared/TicketTrackingSystem.service';
import { ConfirmService, TranslateService } from 'swx.front-end-lib';
import { HasPermissionPipe } from '../shared/HasPermission.pipe';
import { NgIf, NgFor } from '@angular/common';
import { FormsModule } from '@angular/forms';

@Component({
    templateUrl: 'DeicingMobileConfigurationDetail.component.html',
    standalone: true,
    imports: [FormsModule, SwxModule, NgIf, NgFor, HasPermissionPipe]
})
export class DeicingMobileConfigurationDetailComponent {
    item;
    returnPath;
    @ViewChild('ngForm', { static: true }) ngForm;

    constructor(
        private router: Router,
        private viewContainerRef: ViewContainerRef,
        private route: ActivatedRoute,
        private api: ApiService,
        private changes: ChangesService,
        private confirmService: ConfirmService,
        private translateService: TranslateService,
        private ticketTrackingSystem: TicketTrackingSystemService,
    ) {
        this.returnPath = this.route.snapshot.url[0].path.replace('/:id', '');
        const id = this.route.snapshot.params['id'];
        const copyId = this.route.snapshot.queryParams['copy'];
        const isNew = id === 'new';

        if (copyId) {
            this.item = this.api.DeicingMobileConfiguration.get({ id: copyId });
            this.item.$promise.then(() => delete this.item.Id);
        } else if (isNew) {
            this.item = this.api.DeicingMobileConfiguration.create({
                Active: true,
                MobileRequirementCertificationText: 'I certify that the above information is complete, true and correct.',
                MobileShiftCertificationText: 'I certify that the above information is complete, true and correct.',
            });
        } else {
            this.item = this.api.DeicingMobileConfiguration.get({ id: id });
        }

        this.item.$promise.then(() => {
            this.item.DeicingMobileFields = {
                Items: (this.item.DeicingMobileFields || {}).Items || [],
                Statuses: (this.item.DeicingMobileFields || {}).Statuses || []
            }
        });
    }

    save() {
        this.ticketTrackingSystem.trackAndSave(this.viewContainerRef, this.item, this.returnPath);
    }

    cancel() {
        this.router.navigateByUrl(this.returnPath);
    }

    addVehicleItem() {
        this.item.DeicingMobileFields.Items = this.item.DeicingMobileFields.Items || [];
        this.item.DeicingMobileFields.Items.push({});
    }

    removeVehicleItem(item) {
        return this.confirmService.confirm(this.translateService.translate('Are you sure?')).then(() => {
            this.item.DeicingMobileFields.Items.splice(this.item.DeicingMobileFields.Items.indexOf(item), 1);
        }).catch(() => { });
    }

    getMaxVehicleItemWidth() {
        var vehicleItemSize = Math.max(...((this.item.DeicingMobileFields || {}).Items || []).map(d => (d.Name || ' ').length));
        var vehcileStatusSize = Math.max(...((this.item.DeicingMobileFields || {}).Statuses || []).map(d => (d.Name || ' ').length));
        return Math.max(vehicleItemSize, vehcileStatusSize, 15) + "ch"; // default min length = 15
    }

    addVehicleStatus() {
        this.item.DeicingMobileFields.Statuses = this.item.DeicingMobileFields.Statuses || [];
        this.item.DeicingMobileFields.Statuses.push({});
    }

    removeVehicleStatus(item) {
        return this.confirmService.confirm(this.translateService.translate('Are you sure?')).then(() => {
            this.item.DeicingMobileFields.Statuses.splice(this.item.DeicingMobileFields.Statuses.indexOf(item), 1);
        }).catch(() => { });
    }

    viewHistory() {
        this.changes.show(this.viewContainerRef, {
            SubjectType: ['DeicingMobileConfiguration'],
            SubjectId: this.item.Id
        });
    }
}
