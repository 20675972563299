import { Component, Input } from '@angular/core'
import {MobileWeatherInfo} from "../shared/Api.service";
import { SwxModule } from 'swx.front-end-lib';
import { NgFor, NgIf } from '@angular/common';

@Component({
    templateUrl: 'WeatherTypeDialog.component.html',
    standalone: true,
    imports: [NgFor, NgIf, SwxModule]
})
export class WeatherTypeDialogComponent {
    @Input('container') container: JQuery;
    @Input('close') close;
    @Input() weatherTypes: MobileWeatherInfo[];
    selectedWeatherMenuItem;

    selectWeatherType(weatherType) {
        this.close(weatherType);
        this.container.dialog('close');
    }

    open(weatherType) {
        this.weatherTypes = weatherType.Items;
    }

    cancel() {
        this.container.dialog('close');
    }
}
