import {Component} from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import {RawDataViewDialogComponent} from "./rawDataViewDialog/RawDataViewDialog.component";
import {Dialog} from "@angular/cdk/dialog";

@Component({
    template: `
        <a (click)="viewData(params)" class="mdi mdi-eye"></a>`,
    standalone: true
})
export class RawDataCellRendererComponent implements ICellRendererAngularComp {
    public params: any;

    constructor(
        private dialog: Dialog,
    ) {
    }

    agInit(params: any): void {
        this.params = params;
    }

    refresh(): boolean {
        return false;
    }

    viewData({ data }) {
        const rawData = data.RawData;

        this.dialog.open(RawDataViewDialogComponent,
            {
                data: {
                    jsonData: rawData,
                },
                width: '700px',
                height: '320px',
            });
    }
}


