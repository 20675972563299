import {Component, ViewChild, ViewContainerRef} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService } from '../shared/Api.service';
import { ChangesService, SwxModule } from 'swx.front-end-lib';
import { TicketTrackingSystemService } from '../shared/TicketTrackingSystem.service';
import { HasPermissionPipe } from '../shared/HasPermission.pipe';
import { NgIf, NgFor } from '@angular/common';
import { FormsModule } from '@angular/forms';

@Component({
    templateUrl: 'DeicingFluidDetail.component.html',
    standalone: true,
    imports: [FormsModule, SwxModule, NgIf, NgFor, HasPermissionPipe]
})
export class DeicingFluidDetailComponent {
    item;
    returnPath;
    @ViewChild('ngForm', { static: true }) ngForm;
    fluidTypes = this.api.FluidType.query();

    constructor(
        private router: Router,
        private viewContainerRef: ViewContainerRef,
        private route: ActivatedRoute,
        private api: ApiService,
        private changes: ChangesService,
        private ticketTrackingSystem: TicketTrackingSystemService,
    ) {
        this.returnPath = this.route.snapshot.url[0].path.replace('/:id', '');
        const id = this.route.snapshot.params['id'];
        const copyId = this.route.snapshot.queryParams['copy'];
        const isNew = id === 'new';

        if (copyId) {
            this.item = this.api.DeicingFluid.get({ id: copyId });
            this.item.$promise.then(() => delete this.item.Id);
        } else if (isNew) {
            this.item = this.api.DeicingFluid.create({
                Active: true
            });
        } else {
            this.item = this.api.DeicingFluid.get({ id: id });
        }
    }

    save() {
        this.ticketTrackingSystem.trackAndSave(this.viewContainerRef, this.item, this.returnPath);
    }

    cancel() {
        this.router.navigateByUrl(this.returnPath);
    }

    viewHistory() {
        this.changes.show(this.viewContainerRef, {
            SubjectType: ['DeicingFluid'],
            SubjectId: this.item.Id
        });
    }
}
