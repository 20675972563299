import {Component} from "@angular/core";
import {NgIf} from "@angular/common";
import {ICellRendererAngularComp} from "ag-grid-angular";
import {DiffDialogComponent} from "swx.front-end-lib";
import {Dialog} from "@angular/cdk/dialog";

@Component({
    selector: 'diff-cell',
    template: `<a href="javascript:;" *ngIf='params.data?.Diff' (click)='viewDiff(params.data)' class='mdi mdi-eye'></a>`,
    standalone: true,
    imports: [NgIf]
})
export class DiffCellRendererComponent implements ICellRendererAngularComp {
    public params: any;

    constructor(
        private dialog: Dialog,
    ) {
    }

    agInit(params: any): void {
        this.params = params;
    }

    refresh(): boolean {
        return false;
    }

    viewDiff(row) {
        this.dialog.open(DiffDialogComponent,
            {
                data: {
                    diff: JSON.parse(row.Diff),
                },
                width: '700px',
                height: '550px',
            });
    }
}
