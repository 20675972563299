<swx-dialog cdkDrag>
    <swx-dialog-header cdkDragHandle>{{title}}</swx-dialog-header>
    <swx-dialog-content class="intensityPopup">
        <div class="list">
            <label class="item" *ngFor="let intensity of snowVisibilities.PortalIntensities" (click)="selectIntensity(intensity);">
                <div class="item-content text-left" [style.border]="(selectedIntensity === intensity) ? '5px solid #387ef5' : '1px solid gray'" [style.margin-bottom]="'5px'">
                    {{intensity.Name}}
                    <span *ngIf="selectedIntensity === intensity" class="mdi mdi-check mdi-24px"></span>
                </div>
            </label>
        </div>
    </swx-dialog-content>
</swx-dialog>
