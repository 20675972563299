import { Component } from '@angular/core';
import { ApiService } from '../shared/Api.service';
import { FormsModule } from '@angular/forms';
import { RouterLink } from '@angular/router';
import { NgIf } from '@angular/common';
import { SwxModule } from 'swx.front-end-lib';

@Component({
    selector: 'login',
    templateUrl: 'ForgotPassword.component.html',
    standalone: true,
    imports: [SwxModule, NgIf, RouterLink, FormsModule]
})
export class ForgotPasswordComponent {
    email: string;
    sent = false;
    error = false;

    constructor(
        private api: ApiService
    ) {
    }

    submit() {
        this.api.PasswordResetToken.post({
                Email: this.email
            })
            .then(result => {
                    if (result) {
                        this.sent = true;
                    } else {
                        this.error = true;
                    }
                },
                () => {
                    this.error = true;
                }
            );
    }
}