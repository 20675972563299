import { Component, OnDestroy } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
    templateUrl: './InspectionCellRenderer.component.html',
    standalone: true
})
export class InspectionCellRenderer implements ICellRendererAngularComp, OnDestroy {

    value = [];

    agInit(params: ICellRendererParams): void {
        this.value = params.value;
    }

    asString(inspectionItems): string {
        return this.value
            .map((inspection: {Name: string, Status: string}) => {
                return [inspection.Name, inspection.Status].join(':');
            })
            .join(',');
    }

    refresh(params): boolean {
        return false;
    }

    ngOnDestroy(): void { }
}