import {Component, ViewChild, ViewContainerRef} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService } from '../shared/Api.service';
import { ChangesService, SwxModule } from 'swx.front-end-lib';
import { DialogService } from 'swx.front-end-lib';
import { TicketTrackingSystemService } from '../shared/TicketTrackingSystem.service';
import { DeicingFormConfigurationService } from './DeicingFormConfiguration.service';
import { DeicingFormFieldOptionsDialogComponent } from './DeicingFormFieldOptionsDialog.component';
import { IDeicingFormConfiguration } from './IDeicingFormConfiguration.model';
import { HasPermissionPipe } from '../shared/HasPermission.pipe';
import { NgIf, NgFor } from '@angular/common';
import { FormsModule } from '@angular/forms';

@Component({
    templateUrl: 'DeicingFormConfigurationDetail.component.html',
    standalone: true,
    imports: [FormsModule, SwxModule, NgIf, NgFor, HasPermissionPipe]
})
export class DeicingFormConfigurationDetailComponent {
    item;
    returnPath;
    tab;
    @ViewChild('ngForm', { static: true }) ngForm;
    confirm;

    constructor(
        private router: Router,
        private viewContainerRef: ViewContainerRef,
        private route: ActivatedRoute,
        private api: ApiService,
        private changes: ChangesService,
        private dialogService: DialogService,
        private ticketTrackingSystem: TicketTrackingSystemService,
        private deicingFormConfigService: DeicingFormConfigurationService
    ) {
        this.returnPath = this.route.snapshot.url[0].path.replace('/:id', '');
        const id = this.route.snapshot.params['id'];
        const copyId = this.route.snapshot.queryParams['copy'];
        const isNew = id === 'new';

        this.confirm = confirm;

        const filterDeicingFormFields = (item: IDeicingFormConfiguration) => {
            item.DeicingFormFields = item.DeicingFormFields || [];
            let maxOrder = item.DeicingFormFields.map(f => f.Order).reduce((f1, f2) => Math.max(f1, f2), 0);
            item.DeicingFormFields = this.deicingFormConfigService.availableDeicingFormFields.map(availableField => {
                var existingField = item.DeicingFormFields.find(f => f.Id === availableField.Id);
                if (!existingField) {
                    maxOrder++;
                    existingField = {
                        ...availableField,
                        Order: maxOrder
                    };
                    item.DeicingFormFields.push(existingField);
                }
                
                existingField.Placeholder = availableField.Name;
                existingField.Required = existingField.Required || availableField.Required;
                existingField.Optional = availableField.Optional;
                existingField.Select = availableField.Select;
                existingField.Multiselect = availableField.Multiselect;
                existingField.Autocomplete = availableField.Autocomplete;

                existingField.UseFahrenheit = existingField.UseFahrenheit || availableField.UseFahrenheit;
                existingField.UseGallons = existingField.UseGallons || availableField.UseGallons;

                return existingField;
            });
        }

        if (copyId) {
            this.item = this.api.DeicingFormConfiguration.get({ id: copyId });
            this.item.$promise.then(() => {
                delete this.item.Id;
            });
        } else if (isNew) {
            this.item = this.api.DeicingFormConfiguration.create();
        } else {
            this.item = this.api.DeicingFormConfiguration.get({ id: id });
        }
        
        this.item.$promise.then(() => {
            filterDeicingFormFields(this.item);

            setTimeout(() => {
                this.ngForm.form.controls.deicingForm.updateValueAndValidity();
            });
        });

        this.tab = location.hash ? location.hash.substring(1) : 'basicInfo';
    }

    save() {
        this.ticketTrackingSystem.trackAndSave(this.viewContainerRef, this.item, this.returnPath);
    }

    cancel() {
        this.router.navigateByUrl(this.returnPath);
    }

    viewHistory() {
        this.changes.show(this.viewContainerRef, {
            SubjectType: ['DeicingFormConfiguration'],
            SubjectId: this.item.Id
        });
    };

    switchTab(tab) {
        location.hash = tab;
        this.tab = tab;
    };

    editOptions(field) {
        field.Options = field.Options || [];
        this.dialogService.show(this.viewContainerRef, DeicingFormFieldOptionsDialogComponent,
            {
                field: field,
                parentForm: this.ngForm.form
            },
            {
                title: 'Options',
                width: 300,
                height: 400,
                modal: true
            });
    }
}
