import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { enableProdMode, importProvidersFrom } from '@angular/core';

import { environment } from './environments/environment';
import { AppComponent } from './app.component';
import { FormsModule } from '@angular/forms';
import { DeicingRecordUploadLogComponent } from './deicingRecordUploadLog/deicingRecordUploadLog.component';
import { DeicingRecordImportLogComponent } from './deicingRecordImportLog/deicingRecordImportLog.component';
import { TruckInfoComponent } from './truckInfo/TruckInfo.component';
import { LoginHistoryComponent } from './loginHistory/LoginHistory.component';
import { HomeComponent } from './home/Home.component';
import { DeicingMobileConfigurationDetailComponent } from './deicingMobileConfiguration/DeicingMobileConfigurationDetail.component';
import { DeicingMobileConfigurationListComponent } from './deicingMobileConfiguration/DeicingMobileConfigurationList.component';
import { CoordinatorComponent } from './coordinator/Coordinator.component';
import { UserProfileComponent } from './login/UserProfile.component';
import { ChangeGridComponent } from './change/ChangeGrid.component';
import { DeicingRecordDataUploadComponent } from './deicingRecord/DeicingRecordDataUpload.component';
import { DeicingRecordDetailComponent } from './deicingRecord/DeicingRecordDetail.component';
import { DeicingRecordGridComponent } from './deicingRecord/DeicingRecordGrid.component';
import { AirlineDetailComponent } from './airline/AirlineDetail.component';
import { AirlineListComponent } from './airline/AirlineList.component';
import { DeicingFluidDetailComponent } from './deicingFluid/DeicingFluidDetail.component';
import { DeicingFluidListComponent } from './deicingFluid/DeicingFluidList.component';
import { DeicingFormConfigurationDetailComponent } from './deicingFormConfiguration/DeicingFormConfigurationDetail.component';
import { DeicingFormConfigurationListComponent } from './deicingFormConfiguration/DeicingFormConfigurationList.component';
import { ServiceProviderDetailComponent } from './serviceProvider/ServiceProviderDetail.component';
import { ServiceProviderListComponent } from './serviceProvider/ServiceProviderList.component';
import { UserListComponent } from './user/UserList.component';
import { UserDetailComponent } from './user/UserDetail.component';
import { ResetPasswordComponent } from './login/ResetPassword.component';
import { ForgotPasswordComponent } from './login/ForgotPassword.component';
import { LoginComponent } from './login/Login.component';
import { provideRouter } from '@angular/router';
import { BrowserModule, bootstrapApplication } from '@angular/platform-browser';
import { ApiService } from './shared/Api.service';
import { AuthInterceptorService, ExportApi, GridModule, SwxModule, AuthModule } from 'swx.front-end-lib';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { TicketTrackingSystemService } from './shared/TicketTrackingSystem.service';
import { AuthGuard } from './shared/AuthGuard.service';
import { HasPermissionService } from './shared/HasPermission.pipe';
import { DecimalPipe } from '@angular/common';

if (environment.production) {
    enableProdMode();
}

bootstrapApplication(AppComponent, {
    providers: [
        importProvidersFrom(BrowserModule, FormsModule, GridModule, SwxModule.forRoot(environment), AuthModule.forRoot({
            tokenUrl: `${environment.apiUrl}/oauth/access_token`,
            userUrl: `${environment.apiUrl}/user/me`,
            storageKey: 'access_token',
        })),
        DecimalPipe,
        HasPermissionService,
        AuthGuard,
        TicketTrackingSystemService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptorService,
            multi: true,
        },
        {
            provide: ExportApi,
            useClass: ApiService,
        },
        provideHttpClient(withInterceptorsFromDi()),
        provideRouter([
            { path: 'login', component: LoginComponent },
            { path: 'forgotPassword', component: ForgotPasswordComponent },
            { path: 'resetPassword/:id', component: ResetPasswordComponent },
            { path: 'users/:id', component: UserDetailComponent, canActivate: [AuthGuard] },
            { path: 'users', component: UserListComponent, canActivate: [AuthGuard] },
            { path: 'serviceProviders', component: ServiceProviderListComponent, canActivate: [AuthGuard] },
            { path: 'serviceProviders/:id', component: ServiceProviderDetailComponent, canActivate: [AuthGuard] },
            {
                path: 'deicingFormConfigurations',
                component: DeicingFormConfigurationListComponent,
                canActivate: [AuthGuard],
            },
            {
                path: 'deicingFormConfigurations/:id',
                component: DeicingFormConfigurationDetailComponent,
                canActivate: [AuthGuard],
            },
            { path: 'deicingFluids', component: DeicingFluidListComponent, canActivate: [AuthGuard] },
            { path: 'deicingFluids/:id', component: DeicingFluidDetailComponent, canActivate: [AuthGuard] },
            { path: 'airlines', component: AirlineListComponent, canActivate: [AuthGuard] },
            { path: 'airlines/:id', component: AirlineDetailComponent, canActivate: [AuthGuard] },
            {
                path: 'deicingRecordsData',
                component: DeicingRecordGridComponent,
                canActivate: [AuthGuard],
                data: { role: 'deicingRecords' },
            },
            { path: 'deicingRecords/:id', component: DeicingRecordDetailComponent, canActivate: [AuthGuard] },
            { path: 'deicingRecordsUpload', component: DeicingRecordDataUploadComponent, canActivate: [AuthGuard] },
            {
                path: 'airlineDeicingRecordsData',
                component: DeicingRecordGridComponent,
                canActivate: [AuthGuard],
                data: { role: 'airline' },
            },
            {
                path: 'flightOpsDeicingRecordsData',
                component: DeicingRecordGridComponent,
                canActivate: [AuthGuard],
                data: { role: 'flightOps' },
            },
            { path: 'changes', component: ChangeGridComponent, canActivate: [AuthGuard] },
            { path: 'userProfile', component: UserProfileComponent, canActivate: [AuthGuard] },
            { path: 'coordinator', component: CoordinatorComponent, canActivate: [AuthGuard] },
            {
                path: 'deicingMobileConfigurations',
                component: DeicingMobileConfigurationListComponent,
                canActivate: [AuthGuard],
            },
            {
                path: 'deicingMobileConfigurations/:id',
                component: DeicingMobileConfigurationDetailComponent,
                canActivate: [AuthGuard],
            },
            { path: '', component: HomeComponent },
            { path: 'loginHistory', component: LoginHistoryComponent, canActivate: [AuthGuard] },
            { path: 'truckInfo', component: TruckInfoComponent, canActivate: [AuthGuard] },
            { path: 'deicingRecordImportLog', component: DeicingRecordImportLogComponent, canActivate: [AuthGuard] },
            { path: 'deicingRecordUploadLog', component: DeicingRecordUploadLogComponent, canActivate: [AuthGuard] },
            //{ path: '**', component: PageNotFoundComponent }
        ])
    ]
})
    .catch(err => console.error(err));
