import {Component, Inject, OnInit} from "@angular/core";
import {FormsModule} from "@angular/forms";
import {BusyIndicatorService, DialogComponent, DialogContentComponent, DialogHeaderComponent, SwxModule} from "swx.front-end-lib";
import {TicketTrackingSystemService} from "../TicketTrackingSystem.service";
import {DIALOG_DATA, DialogRef} from "@angular/cdk/dialog";

@Component({
    templateUrl: 'TicketTrackingCommentDialog.component.html',
    standalone: true,
    imports: [FormsModule, SwxModule, DialogComponent, DialogHeaderComponent, DialogContentComponent]
})
export class TicketTrackingCommentDialogComponent implements  OnInit {
    ticketNumber: string = '';
    comments: string = '';
    autocompleteOptions: JQueryUI.AutocompleteOptions;

    constructor(@Inject(DIALOG_DATA) private data,
                private dialogRef: DialogRef,
                private ticketTrackingSystem: TicketTrackingSystemService,
                private busyIndicator: BusyIndicatorService) {
    }

    ngOnInit(): void {
        this.autocompleteOptions = {
            minLength: 2,
            delay: 1000,
            source: (request, response) => {
                this.busyIndicator.setBusy(true);
                this.ticketTrackingSystem.getIssues(request.term)
                    .then(issues => {
                        const suggestions = issues.map(issue => ({
                            label: issue.Id + " | " + issue.Description,
                            value: issue.Id
                        }));

                        response(suggestions);
                    })
                    .catch(() => {
                        response([]);
                    })
                    .finally(() => this.busyIndicator.setBusy(false));
            }
        };
    }

    save() {
        this.dialogRef.close({
            TicketNumber: this.ticketNumber,
            Comment: this.comments
        });
    }

    cancel() {
        this.dialogRef.close();
    }
}
