import {Component, ViewChild, Host, OnInit, ViewContainerRef, OnDestroy} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService } from '../shared/Api.service';
import { TranslateService, NotificationService, ConfirmService, FilterPipe, GridComponent, SwxModule, GridModule } from 'swx.front-end-lib';
import { ChangesService } from 'swx.front-end-lib';
import { TicketTrackingSystemService } from '../shared/TicketTrackingSystem.service';
import { HasPermissionService, HasPermissionPipe } from '../shared/HasPermission.pipe';
import { AirlineSelectionDialogComponent } from '../user/airlineSelectionDialog/AirlineSelectionDialog.component';
import { RequireItemsDirective } from '../requireItems/RequireItems.directive';
import { NgIf, NgFor } from '@angular/common';
import { FormsModule } from '@angular/forms';
import {Dialog, DialogRef} from "@angular/cdk/dialog";
import {Subscription} from "rxjs";

@Component({
    templateUrl: 'ServiceProviderDetail.component.html',
    standalone: true,
    imports: [FormsModule, SwxModule, NgIf, NgFor, GridModule, RequireItemsDirective, HasPermissionPipe]
})
export class ServiceProviderDetailComponent implements OnInit, OnDestroy {
    item;
    returnPath;
    wingMaterialTypes = this.api.Permission.query();
    airports = this.api.Airport.query();
    airlines = this.api.Airline.query({ SkipPermissions: true });
    temperatureUnits = this.api.TemperatureUnit.query();
    volumeUnits = this.api.VolumeUnit.query();
    deicingServiceTypes = this.api.DeicingServiceType.query();
    deicingFluids = this.api.DeicingFluid.query();
    deicingFormConfigurations = this.api.DeicingFormConfiguration.query();
    deicingMobileConfigurations = this.api.DeicingMobileConfiguration.query();
    tab;
    @ViewChild('ngForm', { static: true }) ngForm;
    confirm;
    orderByFieldOne;
    orderByReverseOne;
    airline;
    airlineSearchQuery;
    deicingFluid;
    deicingFluidSearchQuery;
    contactSearchQuery;
    vehicleSearchQuery;
    hasServiceProviderApi = false;
    airlinesColumnDefs = null;
    @ViewChild('airlinesGrid', { static: false }) airlinesGrid: GridComponent;
    exportDeicingServiceProviderDeicingAirlinesResource = this.api.DeicingServiceProviderDeicingAirlineExport;
    exportDeicingServiceProviderDeicingAirlinesQuery: any = {};

    deicingRecordFormats: {[key:string]: string};

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private api: ApiService,
        private translateService: TranslateService,
        private notification: NotificationService,
        private changes: ChangesService,
        private confirmService: ConfirmService,
        private ticketTrackingSystem: TicketTrackingSystemService,
        public hasPermissionService: HasPermissionService,
        private dialog: Dialog,
    ) {
    }

    ngOnInit() {
        this.returnPath = this.route.snapshot.url[0].path.replace('/:id', '');
        const id = this.route.snapshot.params['id'];
        const copyId = this.route.snapshot.queryParams['copy'];
        const isNew = id === 'new';
        this.confirm = confirm;

        this.deicingRecordFormats = {
            "Standard": "Standard",
            "AAL": "AAL",
            "Airpro": "Airpro",
        };

        this.exportDeicingServiceProviderDeicingAirlinesQuery = {
	        DeicingServiceProviderId: id,
        };
        
        if (copyId) {
            this.item = this.api.ServiceProvider.get({ id: copyId });
            this.item.$promise.then(() => {
                delete this.item.Id;
                
                if (this.item.DeicingServiceProviderAirlines) {
                    this.item.DeicingServiceProviderAirlines.forEach(related => {
                        delete related.ServiceProviderId;
                    });
                }

                if (this.item.DeicingServiceProviderDeicingFluids) {
                    this.item.DeicingServiceProviderDeicingFluids.forEach(related => {
                        delete related.DeicingServiceProviderId;
                    });
                }

                if (this.item.DeicingServiceProviderContacts) {
                    this.item.DeicingServiceProviderContacts.forEach(related => {
                        delete related.DeicingServiceProviderId;
                    });
                }

                if (this.item.DeicingServiceProviderVehicles) {
                    this.item.DeicingServiceProviderVehicles.forEach(related => {
                        delete related.DeicingServiceProviderId;
                    });
                }
            });
        } else if (isNew) {
            this.item = this.api.ServiceProvider.create();
        } else {
            this.item = this.api.ServiceProvider.get({ id: id });
            this.api.ServiceProviderApi.get({ id: id }).$promise.then(result => {
                this.hasServiceProviderApi = result.HasApi;
            });
        }
        
        Promise.all([
            this.item.$promise,
            this.airlines.$promise,
            this.deicingServiceTypes.$promise
        ]).then(() => {
            if (this.item.DeicingServiceProviderDeicingFluids) {
                this.deicingFluids.$promise.then(() => {
                    this.item.DeicingServiceProviderDeicingFluids.sort((a, b) => {
                        var deicingFluidA = this.deicingFluids.filter(r => r.Id === a.DeicingFluidId)[0];
                        var deicingFluidB = this.deicingFluids.filter(r => r.Id === b.DeicingFluidId)[0];
                        if (deicingFluidA.Name < deicingFluidB.Name) return -1;
                        if (deicingFluidA.Name > deicingFluidB.Name) return 1;
                        return 0;
                    });
                });
            }

            this.airlinesColumnDefs = [
                { colId: "AirlineId", field: "AirlineId", headerName: "#", width: 60, filterType: "integer" },
                { colId: "Name", field: "Name", headerName: "Name", width: 300, cellRenderer: c => {
                    if (c.data == null) return '';
                    var container = document.createElement('div');
                    if (this.hasPermissionService.hasPermission('ConfigViewAirlines')) {
                        container.innerHTML += '<a href="/airlines/' + c.data.AirlineId + '" onclick="event.preventDefault();">' + c.value + '</a>';
                        container.querySelector('a').addEventListener('click',
                            e => {
                                if (this.ngForm.form.pristine) {
                                    this.router.navigateByUrl(e.target['getAttribute']('href'));
                                } else {
                                    this.confirmService.confirm(this.translateService.translate('Are you sure you want to discard your changes?')).then(() => {
                                        this.router.navigateByUrl(e.target['getAttribute']('href'));
                                    }).catch(() => {});
                                }
                            });
                    } else {
                        container.innerHTML = c.value;
                    }
                    return container;
                } },
                { colId: "IATACode", field: "IATACode", headerName: "IATA code", width: 120 },
                { colId: "ICAOCode", field: "ICAOCode", headerName: "ICAO code", width: 120 },
                { colId: "DeicingServiceType", field: "DeicingServiceType", headerName: "Service type", width: 200, cellRenderer: "deicingServiceTypeCellRenderer" },
	            { colId: "_spacer", "flex": 1, excelIgnore: true },
                { colId: "Actions", field: "AirlineId", headerName: "", width: 50, cellClass: "actions", cellRenderer: c => {
                    if (c.data == null) return '';
                    var container = document.createElement('div');
                    if (this.hasPermissionService.hasPermission('ConfigEditServiceProviderAirlines')) {
                        container.innerHTML += '<a class="mdi mdi-delete" title="' + this.translateService.translate('Delete') + '" onclick="event.preventDefault();"></a>';
                        container.querySelector('.mdi-delete').addEventListener('click', () => this.removeServiceProviderAirline(c.data).then(() => c.api.setRowData(this.transformDeicingServiceProviderAirlines(this.item.DeicingServiceProviderAirlines))));
                    }
                    return container;
                }, pinned: "right", excelIgnore: true },
            ];

            setTimeout(() => {
                this.ngForm.form.controls.airlinesForm.updateValueAndValidity();
                this.ngForm.form.controls.contactsForm.updateValueAndValidity();
                this.ngForm.form.controls.deicingFluidsForm.updateValueAndValidity();
                this.ngForm.form.controls.vehiclesForm.updateValueAndValidity();
            });
        });

        this.tab = location.hash ? location.hash.substring(1) : 'basicInfo';
    }

    transformDeicingServiceProviderAirlines(items) {
        if (items == null) return items;
        items.forEach(x => {
            var airline = this.airlines.find(a => a.Id === x.AirlineId);
            x.Name = airline.Name;
            x.IATACode = airline.IATACode;
            x.ICAOCode = airline.ICAOCode;
        });

        return items;
    }

    filter = (items, searchQuery) => FilterPipe.instance.transform(items, searchQuery);

    save() {
        this.ticketTrackingSystem.trackAndSave(this.item, this.returnPath);
    }

    cancel() {
        this.router.navigateByUrl(this.returnPath);
    }

    viewHistory() {
        this.changes.show({
            SubjectType: ['ServiceProvider'],
            SubjectId: this.item.Id
        });
    };

    switchTab(tab) {
        location.hash = tab;
        this.tab = tab;
    };

    logoChanged() {
        var img = new Image();
        img.src = this.item.Logo;
        var maxWidth = 215;
        var maxHeight = 50;

        img.onload = () => {
            if (img.width > maxWidth || img.height > maxHeight) {
                this.notification.show(this.translateService.translate('Logo size error (' + img.width + ' / ' + img.height + ') : Maximum allowed (in pixels) is ' + maxWidth + ' (width) and ' + maxHeight + ' (height)'), { type: 'error' });
            }
        };
    }

    airlineSelectionDialogRef: DialogRef<number[], AirlineSelectionDialogComponent>;
    airlineSelectionDialogCloseSub: Subscription;
    
    showAirlinesPopup() {
        this.airlineSelectionDialogRef = this.dialog.open(AirlineSelectionDialogComponent,
            {
                data: {
                    title: 'Select airlines',
                    options: this.airlinesNotEnabled(this.airlines, this.item.DeicingServiceProviderAirlines),
                },
                width: '80%',
                height: window.innerHeight * 0.8 + 'px',
            });
        this.airlineSelectionDialogCloseSub = this.airlineSelectionDialogRef.closed.subscribe(selectedIds => {
            if (selectedIds) {
                this.onCloseAirlinesPopup(selectedIds);
            }
        })
    }

    private onCloseAirlinesPopup(selectedIds: Number[]) {
        this.item.DeicingServiceProviderAirlines = this.item.DeicingServiceProviderAirlines || [];
        selectedIds.forEach(id => this.item.DeicingServiceProviderAirlines.push({
            AirlineId: id,
            DeicingServiceType: null,
        }));
        this.airlinesGrid.gridApi.setRowData(this.transformDeicingServiceProviderAirlines(this.item.DeicingServiceProviderAirlines));
        this.ngForm.form.controls.airlinesForm.updateValueAndValidity();
        this.ngForm.form.controls.airlinesForm.markAsDirty();
    }

    removeServiceProviderAirline(item) {
        return this.confirmService.confirm(this.translateService.translate('Are you sure?')).then(() => {
            this.item.DeicingServiceProviderAirlines.splice(this.item.DeicingServiceProviderAirlines.indexOf(item), 1);
            this.ngForm.form.controls.airlinesForm.updateValueAndValidity();
            this.ngForm.form.controls.airlinesForm.markAsDirty();
        }).catch(() => {});
    }

    airlinesNotEnabled(airlines: any[], serviceProviderAirlines: any[]) {
        if (!airlines || !serviceProviderAirlines) return airlines;

        return airlines.filter(a => serviceProviderAirlines.every(r => r.AirlineId !== a.Id as any));
    }

    addServiceProviderDeicingFluid(deicingFluid) {
        this.item.DeicingServiceProviderDeicingFluids = this.item.DeicingServiceProviderDeicingFluids || [];
        this.item.DeicingServiceProviderDeicingFluids.push({
            DeicingFluidId: deicingFluid.Id
        });
        this.ngForm.form.controls.deicingFluidsForm.updateValueAndValidity();
        this.ngForm.form.controls.deicingFluidsForm.markAsDirty();
    }

    removeServiceProviderDeicingFluid(item) {
        return this.confirmService.confirm(this.translateService.translate('Are you sure?')).then(() => {
            this.item.DeicingServiceProviderDeicingFluids.splice(this.item.DeicingServiceProviderDeicingFluids.indexOf(item), 1);
            this.ngForm.form.controls.deicingFluidsForm.updateValueAndValidity();
            this.ngForm.form.controls.deicingFluidsForm.markAsDirty();
        }).catch(() => {});
    }
    
    addServiceProviderContact() {
        this.item.DeicingServiceProviderContacts = this.item.DeicingServiceProviderContacts || [];
        this.item.DeicingServiceProviderContacts.push({});
        this.ngForm.form.controls.contactsForm.updateValueAndValidity();
        this.ngForm.form.controls.contactsForm.markAsDirty();
    }

    removeServiceProviderContact(item) {
        return this.confirmService.confirm(this.translateService.translate('Are you sure?')).then(() => {
            this.item.DeicingServiceProviderContacts.splice(this.item.DeicingServiceProviderContacts.indexOf(item), 1);
            this.ngForm.form.controls.contactsForm.updateValueAndValidity();
            this.ngForm.form.controls.contactsForm.markAsDirty();
        }).catch(() => {});
    }

    addServiceProviderVehicle() {
        this.item.DeicingServiceProviderVehicles = this.item.DeicingServiceProviderVehicles || [];
        this.item.DeicingServiceProviderVehicles.push({});
        this.ngForm.form.controls.vehiclesForm.updateValueAndValidity();
        this.ngForm.form.controls.vehiclesForm.markAsDirty();
    }

    removeServiceProviderVehicle(item) {
        return this.confirmService.confirm(this.translateService.translate('Are you sure?')).then(() => {
            this.item.DeicingServiceProviderVehicles.splice(this.item.DeicingServiceProviderVehicles.indexOf(item), 1);
            this.ngForm.form.controls.vehiclesForm.updateValueAndValidity();
            this.ngForm.form.controls.vehiclesForm.markAsDirty();
        }).catch(() => {});
    }

    checkServiceProviderVehicleUnicity(serviceProviderVehicle, index) {
	    serviceProviderVehicle._notModified = false || !serviceProviderVehicle.ManufacturerAndModelCategory;

        this.item.DeicingServiceProviderVehicles = this.item.DeicingServiceProviderVehicles || [];
        var candidates = this.item.DeicingServiceProviderVehicles.filter((item) => {
            return item !== serviceProviderVehicle;
        });

        var found = candidates.find(c => c.ManufacturerAndModelCategory ===
            serviceProviderVehicle.ManufacturerAndModelCategory);

        if (found) {
            alert("Vehicle with id (" + (serviceProviderVehicle.ManufacturerAndModelCategory + ") already exists"));
            serviceProviderVehicle.ManufacturerAndModelCategory = '';
        }
    }

    prefillVehicleCustomName(serviceProviderVehicle) {
	    if (serviceProviderVehicle._notModified) {
		    serviceProviderVehicle.ManufacturerAndModelCategory = serviceProviderVehicle.ManufacturerAndModel;
	    }
    }

    serviceProviderSearchFilter(items, expression, searchType) {
        if (typeof items === 'undefined' || items === null || typeof items.length === 'undefined' || items.length === 0)
            return [];

        if (!expression) {
            return items;
        }

        function isFound(search: any, value: any) {
            return value.toUpperCase().indexOf(search.toUpperCase()) > -1;
        }

        switch (searchType) {
        case 'serviceProviderDeicingFluid':
            return items.filter(item => {
                var related = this.deicingFluids.filter(val => (val.Id === item.DeicingFluidId))[0];
                return isFound(expression, related.Name);
            });
        default:
            return items;
        }
    }
    
    ngOnDestroy() {
        if (this.airlineSelectionDialogCloseSub) {
            this.airlineSelectionDialogCloseSub.unsubscribe();
        }
    }
}


