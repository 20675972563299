import {Component, Inject, OnInit} from '@angular/core'
import {MobileWeatherInfo} from "../shared/Api.service";
import {DialogComponent, DialogContentComponent, DialogHeaderComponent, SwxModule} from 'swx.front-end-lib';
import { NgFor, NgIf } from '@angular/common';
import {DIALOG_DATA, DialogRef} from "@angular/cdk/dialog";

@Component({
    templateUrl: 'WeatherTypeDialog.component.html',
    standalone: true,
    imports: [NgFor, NgIf, SwxModule, DialogComponent, DialogHeaderComponent, DialogContentComponent]
})
export class WeatherTypeDialogComponent implements OnInit {
    title!: string;
    weatherTypes: MobileWeatherInfo[];
    selectedWeatherMenuItem;
    
    constructor(@Inject(DIALOG_DATA) private data,
                private dialogRef: DialogRef) {
    }
    
    ngOnInit() {
        this.title = this.data.title;
        this.weatherTypes = this.data.weatherTypes;
        this.selectedWeatherMenuItem = this.data.selectedWeatherMenuItem;
    }

    selectWeatherType(weatherType: MobileWeatherInfo) {
        this.dialogRef.close(weatherType);
    }

    open(weatherType) {
        this.weatherTypes = weatherType.Items;
    }

    cancel() {
        this.dialogRef.close();
    }
}
