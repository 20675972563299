import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { NgIf } from '@angular/common';

@Component({
    template: `
        <a *ngIf="params.data" (click)="viewData(params)" class="mdi mdi-eye"></a>`,
    standalone: true,
    imports: [NgIf]
})
export class DeicingRecordsCellRendererComponent implements ICellRendererAngularComp {
    public params: any;

    constructor(
        private router: Router,
    ) {
    }

    agInit(params: any): void {
        this.params = params;
    }

    refresh(params): boolean {
        return false;
    }

    viewData({ data }) {
        this.router.navigate(['deicingRecordsData', { uploadId: data.Id }]);
    }
}