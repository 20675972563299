import {Component, Inject, OnInit} from '@angular/core';
import {DIALOG_DATA} from "@angular/cdk/dialog";
import {DialogComponent, DialogContentComponent, DialogHeaderComponent} from "swx.front-end-lib";
import {CdkDrag, CdkDragHandle} from "@angular/cdk/drag-drop";

@Component({
    templateUrl: './DeicingRecordSignatureDialog.component.html',
    standalone: true,
    imports: [DialogComponent, DialogHeaderComponent, DialogContentComponent, CdkDrag, CdkDragHandle]
})
export class DeicingRecordSignatureDialogComponent implements OnInit {
    src!: string;
    
    constructor(@Inject(DIALOG_DATA) private data: { src: string }) {
    }
    
    ngOnInit() {
        this.src = this.data.src;
    }
}
