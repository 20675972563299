<swx-dialog>
    <swx-dialog-header>Options</swx-dialog-header>
    <swx-dialog-content>
        <form #form #ngForm="ngForm" (submit)="field.Options.push(newOption); newOption = null; parentForm.markAsDirty();">
            <h1>This ONE</h1>
            <table class="table" style="width: 100%;">
                <tr>
                    <th>{{'Option'|translate}}</th>
                    <th></th>
                </tr>
                <tr *ngFor="let option of field.Options">
                    <td>{{option}}</td>
                    <td>
                        <a (click)="field.Options.splice(field.Options.indexOf(option), 1); parentForm.markAsDirty();" class="mdi mdi-delete"></a>
                    </td>
                </tr>
            </table>
        </form>
    </swx-dialog-content>
    <swx-dialog-footer>
        <input type="text" [(ngModel)]="newOption" name="newOption" style="width: 150px;" pattern="[^,]+" />
        <input type="submit" value="{{'Add option'|translate}}" [disabled]="newOption == null" />
    </swx-dialog-footer>
</swx-dialog>

