import {Component, Inject, Input, OnInit} from "@angular/core";
import {DialogComponent, DialogContentComponent, DialogFooterComponent, DialogHeaderComponent, SwxModule} from "swx.front-end-lib";
import { NgFor } from "@angular/common";
import { FormsModule } from "@angular/forms";
import {DIALOG_DATA} from "@angular/cdk/dialog";

@Component({
    templateUrl: 'DeicingFormFieldOptionsDialog.component.html',
    standalone: true,
    imports: [FormsModule, NgFor, SwxModule, DialogComponent, DialogHeaderComponent, DialogContentComponent, DialogFooterComponent]
})
export class DeicingFormFieldOptionsDialogComponent implements OnInit {
    field!: any;
    parentForm!: any;
    newOption;
    
    constructor(@Inject(DIALOG_DATA) private data: { field: any, parentForm: any }) {
    }
    
    ngOnInit() {
        this.field = this.data.field;
        this.parentForm = this.data.parentForm;
    }
    
}
