import { Component, ViewChild } from '@angular/core';
import { ApiService } from "../shared/Api.service";
import { FileCellRendererComponent } from "../shared/cellRenderers/fileCellRenderer.component";
import { DeicingRecordsCellRendererComponent } from "../shared/cellRenderers/deicingRecordsCellRenderer.component";
import { GridComponent, ValueFormatters, SwxModule, GridModule } from 'swx.front-end-lib';
import { FormsModule } from '@angular/forms';

@Component({
    templateUrl: './deicingRecordUploadLog.component.html',
    standalone: true,
    imports: [SwxModule, FormsModule, GridModule]
})
export class DeicingRecordUploadLogComponent {
    @ViewChild('grid', { static: true }) grid: GridComponent;
    query: any = {};
    airports = this.api.Airport.query();
    selectedIds: number[] = [];

    datePickerOptions = {
        maxDate: new Date(new Date().getUTCFullYear(), new Date().getUTCMonth(), new Date().getUTCDate(), new Date().getUTCHours(), new Date().getUTCMinutes(), new Date().getUTCSeconds()),
    };

    columnDefs = [
        { colId: "Id", field: "Id", headerName: "#", width: 90, pinned: 'left' },
        { colId: "CreatedDate", field: "CreatedDate", headerName: "Created date", width: 100, valueFormatter: this.formatters.utcDateFormatter(), pinned: 'left' },
        { colId: "CreatedTime", field: "CreatedDate", headerName: "Created time", width: 70, valueFormatter: this.formatters.utcTimeFormatter(), excelIgnore: true, pinned: 'left' },
        {
            colId: 'Records',
            field: null,
            headerName: 'Records',
            width: 150,
            pinned: 'left',
            cellRenderer: DeicingRecordsCellRendererComponent,
        },
        {
            headerName: 'Comment',
            field: 'Comment',
            colId: 'Comment',
        },
        {
            headerName: 'Filename',
            field: 'Filename',
            colId: 'Filename',
            cellRenderer: FileCellRendererComponent,
        },
        { colId: "CreatedByUserName", field: "CreatedByUserName", headerName: "Created by", width: 100 },
        {
            headerName: 'Deleted Date',
            field: 'DeletedDate',
            colId: 'DeletedDate',
            valueFormatter: this.formatters.utcDateTimeFormatter(),
            width: 250,
        },
        {
            headerName: 'Format',
            field: 'Format',
            colId: 'Format',
        },
        {
            headerName: 'Time Zone',
            field: 'TimeZone',
            colId: 'TimeZone',
        },
        {
            headerName: 'Client',
            field: 'Client',
            colId: 'ClientId',
        },
        {
            headerName: 'Selected Service Provider',
            field: 'ServiceProviderName',
            colId: 'ServiceProviderName',
        },
        {
            headerName: 'Selected Airline',
            field: 'Airline',
            colId: 'AirlineId',
        },
        {
            headerName: 'Selected Airport',
            field: 'Airport',
            colId: 'AirportId',
        },
        {
            headerName: 'Max Hot Request Age in Minutes',
            field: 'MaxHotRequestAgeinMinutes',
            colId: 'MaxHotRequestAgeinMinutes',
        },
        {
            headerName: 'Match Date Field',
            field: 'MatchDateField',
            colId: 'MatchDateField',
        },
        {
            headerName: 'Match Identifier Field',
            field: 'MatchIdentifierField',
            colId: 'MatchIdentifierField',
        },
        {
            headerName: 'Skip Other Airline Records',
            field: 'SkipOtherAirlineRecords',
            colId: 'SkipOtherAirlineRecords',
        },
        {
            headerName: 'Skip Hangar Records',
            field: 'SkipHangarRecords',
            colId: 'SkipHangarRecords',
        },
        {
            headerName: 'Inserted',
            field: 'Inserted',
            colId: 'Inserted',
        },
        {
            headerName: 'Errors',
            field: 'Errors',
            colId: 'Errors',
        },
        {
            headerName: 'Consolidate Multiple Rows For Same Flight',
            field: 'ConsolidateMultipleRowsForSameFlight',
            colId: 'ConsolidateMultipleRowsForSameFlight',
        },
        {
            headerName: 'Generate Reference Hots',
            field: 'GenerateReferenceHots',
            colId: 'GenerateReferenceHots',
        },
        {
            headerName: 'Wheels-Up In Utc',
            field: 'WheelsUpInUtc',
            colId: 'WheelsUpInUtc',
        },
    ];

    refresh() {
        window.localStorage['deicingRecordUploadQuery'] = JSON.stringify(this.query);
        this.grid.refresh();
    }

    resetQuery() {
        this.query = {
            FromDate: null,
            ToDate: null,
            Filters: [],
        };
    }

    constructor(
        public api: ApiService,
        private formatters: ValueFormatters,
    ) {
        if (window.localStorage['deicingRecordUploadQuery']) {
            this.query = JSON.parse(window.localStorage['deicingRecordUploadQuery']);
        } else {
            this.resetQuery();
        }
    }
}
