import {Component, OnInit, OnDestroy, ViewContainerRef} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import moment from 'moment';
import 'moment-timezone';
import { ApiService } from '../shared/Api.service';
import { TranslateService, NotificationService, AuthenticationService, ChangesService, SwxModule } from 'swx.front-end-lib';
import { RootScope } from '../shared/RootScope.service';
import { IDeicingFormField } from '../deicingFormConfiguration/IDeicingFormField.model';
import { DeicingFormConfigurationService } from '../deicingFormConfiguration/DeicingFormConfiguration.service';
import { Subscription } from 'rxjs';
import { HasPermissionPipe } from '../shared/HasPermission.pipe';
import { NgIf, NgFor, NgSwitch, NgSwitchCase, NgSwitchDefault } from '@angular/common';
import { FormsModule } from '@angular/forms';

@Component({
    templateUrl: 'DeicingRecordDetail.component.html',
    standalone: true,
    imports: [FormsModule, SwxModule, NgIf, NgFor, NgSwitch, NgSwitchCase, NgSwitchDefault, HasPermissionPipe]
})
export class DeicingRecordDetailComponent implements OnInit, OnDestroy {
    item: any = {};
    returnPath;
    airports = this.api.Airport.query();
    serviceProviders = [];
    airlines = [];
    vehicles = [];
    vehicleOperators = [];
    datePickerOptions: DateTimePickerOptions;
    deicingFormFields: IDeicingFormField[];
    timeZone = moment.tz.guess();
    static locationPromise;
    registrationPrefixes = new Array();
    selectedRegistrationPrefix;
    registrationPattern;
    formConfigurations = {};
    registrationNumbers = [];
    flightNumbers = [];
    equipmentTypeIdentifiers = [];
    checkFlightRecordsTimeout = null;

    routeSub: Subscription;

    constructor(
        private router: Router,
        private viewContainerRef: ViewContainerRef,
        private route: ActivatedRoute,
        private api: ApiService,
        private authentication: AuthenticationService,
        private translateService: TranslateService,
        private notification: NotificationService,
        private changes: ChangesService,
        private $root: RootScope,
        private deicingFormConfigService: DeicingFormConfigurationService
    ) {
        this.returnPath = '/deicingRecordsData';
        
        this.$root.serviceProviderChange.subscribe(() => this.selectServiceProvider());

        const minDateTime = new Date();
        minDateTime.setFullYear(minDateTime.getFullYear() - 5);
        const maxDateTime = new Date();
        maxDateTime.setDate(maxDateTime.getDate() + 1);
        this.datePickerOptions = {
            minDateTime: minDateTime,
            maxDateTime: maxDateTime,
        };


        this.reset();
    }

    ngOnInit(): void {
        this.routeSub = this.route.paramMap.subscribe(params => {
            const id = params.get('id');

            if (id === 'new') {
                this.item = this.api.DeicingRecord.create({ ConfirmNozzleTemperature: false }); // nullable but initialize to false on the form
            }
        });
    }

    ngOnDestroy(): void {
        this.routeSub.unsubscribe();
    }

    datePickerValidator(date: Date) {
        if (date.getMonth() > 4 && date.getMonth() < 8) {
            return { 'date': false };
        }

        return null;
    }

    save() {
        this.checkRegistrationPrefixes(true);
        this.fillFlightNumberNumeric(this.item);
        
        this.item.$save().then(() => {
            this.notification.show(this.translateService.translate('The changes have been saved.'));
            this.router.navigateByUrl(this.returnPath);
        });
    }

    private fillFlightNumberNumeric(item: any): void {
        const flightNumber = item.FlightNumber.trim();
        const airline = this.airlines.find(a => a.Id === item.AirlineId);

        let regexString = `^(\\D*)(\\d+)(\\D*)`;

        if (airline) {
            regexString = `^(${airline.IATACode}|${airline.ICAOCode})(\\d+)`;
        }
        
        const re = new RegExp(regexString, 'i');

        const match = re.exec(flightNumber);

        if (match) {
            this.item.FlightNumberNumeric = match[2];
        }
    }

    saveCopy() {
        this.checkRegistrationPrefixes(true);

        this.item.$save().then(() => {
            this.notification.show(this.translateService.translate('The changes have been saved.'));
            this.reset(this.item.Id);
        });
    }

    reset(copyId?: number) {
        this.item.Id = this.item.Id || (this.route.snapshot.params['id'] === 'new' ? null : this.route.snapshot.params['id']);
        copyId = copyId || this.route.snapshot.queryParams['copy'];
        const isNew = !this.item.Id;

        if (copyId) {
            this.item = this.api.DeicingRecord.get({ id: copyId });
            this.item.$promise.then(() => {
                delete this.item.Id;
                delete this.item.Identifier;
                delete this.item.FlightNumber;
                delete this.item.RegistrationNumber;
                delete this.item.TailNumber;
                delete this.item.DeicingCompleteTime;
                delete this.item.HoldoverStartTime;
                delete this.item.WheelsUpTime;
                delete this.item.ScheduledDepartureTime;
                delete this.item.OffBlockTime;
                delete this.item.AircraftCondition;
                delete this.item.AircraftSides;
                delete this.item.Sections;
                delete this.item.Destination;
                delete this.item.SprayCount;
                delete this.item.Type1Volume;
                delete this.item.Type4Volume;
                delete this.item.PassengerLoad;
                delete this.item.DepartureRunway;
                delete this.item.Remarks;
            });
        } else if (isNew) {
            this.item = this.api.DeicingRecord.create();
        } else {
            this.item = this.api.DeicingRecord.get(this.item.Id);
        }

        Promise.all([this.item.$promise, this.$root.getServiceProviders(), this.$root.getAirlines()]).then(() => {
            this.selectServiceProvider();
        });
    }

    fluidName(fluid) {
	    return 'Type ' + fluid.Type + ' - ' + fluid.Name + (fluid.Dilution ? (' ' + fluid.Dilution) : '');
    }

    private findClosestAirport(airports, coords) {
        var nearestAirport = null;
        var minDistance = 0;

        function toRadians(value) {
            return value * Math.PI / 180;
        }

        airports.forEach(airport => {
            var distanceInMiles = 3959 * Math.acos(
                Math.cos(toRadians(coords.latitude))
                * Math.cos(toRadians(airport.Latitude))
                * Math.cos(toRadians(airport.Longitude) - toRadians(coords.longitude))
                + Math.sin(toRadians(coords.latitude))
                * Math.sin(toRadians(airport.Latitude))
            );

            if (minDistance === 0 || minDistance > distanceInMiles) {
                minDistance = distanceInMiles;
                nearestAirport = airport;
            }
        });

        return nearestAirport;
    }

    airportChanged() {
        return this.airports.$promise.then(() => {
            var airport = this.airports.find(a => a.Id === this.item.AirportId);
            if (airport) {
                this.timeZone = airport.TimeZone;
            }
        });
    }

    checkRegistrationPrefixes(submitted?: boolean) {
        if (!this.airlines || !this.item.AirlineId || !this.deicingFormFields) return;

        const deicingField = this.deicingFormFields.find((field) => field.Id === "RegistrationNumber");

        if (!deicingField) return;

        const airline = this.airlines.find(a => a.Id === this.item.AirlineId);
        
        if (airline == null) return;

        this.registrationPrefixes = airline.AircraftIdentificationPrefix ? airline.AircraftIdentificationPrefix.split(",") : [];

        const airplaneFromList = (airline.Airplanes || []).find(airplane => airplane.TailNumber === this.item[deicingField.Id]);

        if (!submitted) {
            const prefixFound = this.registrationPrefixes.length !== 0;
            this.item[deicingField.Id] = !this.item[deicingField.Id] && prefixFound ? this.registrationPrefixes[0] : this.item[deicingField.Id];
            this.selectedRegistrationPrefix = !this.selectedRegistrationPrefix && prefixFound ? this.registrationPrefixes[0] : this.selectedRegistrationPrefix;
            this.registrationPattern = !(airplaneFromList || {}).AirlineId ? airline.AircraftIdentificationRegexExpression : null;
        } else {
            this.item[deicingField.Id] = airline.AircraftIdentificationUneditablePrefix && !(airplaneFromList || {}).AirlineId ? this.selectedRegistrationPrefix.concat(this.item[deicingField.Id]) : this.item[deicingField.Id];
        }
    }

    checkRegistrationFormat(event: any, field: any) {
        if (this.registrationPrefixes.length === 0) return;
        const value = (event || {}).target ? event.target.value : event;
        const airline = this.airlines.find(a => a.Id === this.item.AirlineId);
        if (airline == null) return;
        const fromAirplaneList = (airline.Airplanes || []).filter((airplane) => airplane.TailNumber === (value)).length > 0;
        this.registrationPattern = !fromAirplaneList ? airline.AircraftIdentificationRegexExpression : null ;
        const prefix = this.registrationPrefixes.find((prefix) => value.startsWith(prefix.trim()));

        if (prefix) {
            this.selectedRegistrationPrefix = prefix;
        }

        if (fromAirplaneList === false) {
            if (airline.AircraftIdentificationUneditablePrefix && prefix) {
                this.item[field.Id] = value.substring(prefix.length, value.length);
            }
        }

        this.checkFlightRecords();
    }

    checkFlightRecords() {
        clearTimeout(this.checkFlightRecordsTimeout);
        this.checkFlightRecordsTimeout = setTimeout(() => {
            this.authentication.getCurrentUser().then(user => {
                var airline = this.airlines.find(a => a.Id === this.item.AirlineId);

                var fallback = () => {
	                if (airline != null) {
		                if (this.registrationNumbers == null || this.registrationNumbers.length === 0) {
			                this.registrationNumbers = airline.Airplanes.map(a => a.TailNumber);
		                }
	                }
                }

                if (this.item.AirportId && this.item.HoldoverStartTime) {
	                this.api.FlightRecord.query({
		                AirlineId: this.item.AirlineId || '',
		                AirportId: this.item.AirportId,
		                HoldoverStartTime: this.item.HoldoverStartTime,
		                FlightNumber: this.item.FlightNumber || '',
		                EquipmentTypeIdentifier: this.item.EquipmentTypeIdentifier || '',
		                RegistrationNumber: this.item.RegistrationNumber || '',
	                }).$promise.then(flightRecords => {
		                this.registrationNumbers = flightRecords.map(fr => fr.RegistrationNumber).filter((value, index, self) => self.indexOf(value) === index);
		                this.flightNumbers = flightRecords.map(fr => fr.FlightIdentifierRaw).filter((value, index, self) => self.indexOf(value) === index);
		                this.equipmentTypeIdentifiers = flightRecords.map(fr => fr.EquipmentTypeIdentifier).filter((value, index, self) => self.indexOf(value) === index);
                        
		                fallback();
	                });
                } else {
	                fallback();
                }
            });
        }, 250);
    }

    selectServiceProvider() {
        Promise.all([this.$root.getServiceProviders(), this.$root.getAirlines(), this.airports.$promise, this.authentication.getCurrentUser()]).then(([serviceProviders, airlines, airports, currentUser]) => {
            this.serviceProviders = serviceProviders;
            this.airlines = airlines;
            
            if (!this.item.ServiceProviderId && currentUser.PrimaryDeicingServiceProviderId) {
                this.item.ServiceProviderId = currentUser.PrimaryDeicingServiceProviderId;
            } else if (this.serviceProviders.length === 1) {
                this.item.ServiceProviderId = this.serviceProviders[0].Id;
            }

            if (this.item.ServiceProviderId) {
                const serviceProvider = this.serviceProviders.find(sp => sp.Id === this.item.ServiceProviderId);

                // Assign vehicles and operators
                this.vehicles = serviceProvider.Vehicles;
                this.vehicleOperators = serviceProvider.Operators;

                if (serviceProvider.DeicingFormConfigurationId) {
                    if (this.formConfigurations[serviceProvider.DeicingFormConfigurationId] == null) {
                        this.formConfigurations[serviceProvider.DeicingFormConfigurationId] = this.api.DeicingFormConfiguration.get(serviceProvider.DeicingFormConfigurationId);
                    }
                    
                    this.formConfigurations[serviceProvider.DeicingFormConfigurationId].$promise.then(deicingFormConfiguration => {
                        this.checkRegistrationPrefixes();
                        this.deicingFormFields = this.getDeicingFormFields(deicingFormConfiguration.DeicingFormFields);
                    });
                } else {
                    this.deicingFormFields = this.getAvailableDeicingFormFields();
                }
            } else {
                this.deicingFormFields = this.getAvailableDeicingFormFields();
            }

            if (currentUser.PrimaryDeicingAirlineId) {
                this.item.AirlineId = currentUser.PrimaryDeicingAirlineId;
            } else if (this.airlines.length === 1) {
                this.item.AirlineId = this.airlines[0].Id;
            }

            this.checkRegistrationPrefixes();

            var availableAirports = this.filterServiceProviderAirports(this.airports);
            if (availableAirports.length === 1) {
                this.item.AirportId = availableAirports[0].Id;
                this.airportChanged();
            } else if (availableAirports.length > 1) {
                if (!DeicingRecordDetailComponent.locationPromise) {
                    DeicingRecordDetailComponent.locationPromise = new Promise((resolve, reject) => {
                        navigator.geolocation.getCurrentPosition(location => resolve(location), e => reject(e));
                    });
                }

                DeicingRecordDetailComponent.locationPromise
                    .then(location => {
                        var nearestAirport = this.findClosestAirport(this.airports, location.coords);
                        if (nearestAirport) {
                            this.item.AirportId = nearestAirport.Id;
                        }
                        this.airportChanged();
                    })
                    .catch(() => {
                        this.authentication.getCurrentUser().then(user => {
                            if (user.LastAuthenticationLatitude != null && user.LastAuthenticationLongitude != null) {
                                var nearestAirport = this.findClosestAirport(this.airports,
                                    {
                                        latitude: user.LastAuthenticationLatitude,
                                        longitude: user.LastAuthenticationLongitude,
                                    });
                                if (nearestAirport) {
                                    this.item.AirportId = nearestAirport.Id;
                                }
                            }

                            this.airportChanged();
                        });
                    });
            }
            
            this.checkFlightRecords();
        });
    }

    filterServiceProviderAirports(airports) {
        if (this.item.ServiceProviderId) {
            var serviceProvider = this.serviceProviders.find(sp => sp.Id === this.item.ServiceProviderId);
            if (serviceProvider.AirportId) {
                return airports.filter(a => a.Id === serviceProvider.AirportId);
            }
        }

        return airports;
    }

    viewHistory() {
        this.changes.show({
            SubjectType: ['DeicingRecord'],
            SubjectId: this.item.Id
        });
    };


    // Returns the default list of form fields with Order assigned
    private getAvailableDeicingFormFields(): IDeicingFormField[] {
        var maxOrder = -1;
        return this.deicingFormConfigService.availableDeicingFormFields.map(availableField => {
            maxOrder++;
            return Object.assign({
                Order: maxOrder
            },
                availableField);
        });
    }

    private getDeicingFormFields(fields: IDeicingFormField[]): IDeicingFormField[] {
        return fields.map(existingField => {
            var availableField = this.deicingFormConfigService.availableDeicingFormFields.find(availableField => availableField.Id === existingField.Id);
            if (!availableField) return null;

            existingField.Placeholder = availableField.Name;
            existingField.Required = existingField.Required || availableField.Required;
            existingField.Optional = availableField.Optional;
            existingField.Select = availableField.Select;
            existingField.Multiselect = availableField.Multiselect;
            existingField.Autocomplete = availableField.Autocomplete;

            existingField.UseFahrenheit = existingField.UseFahrenheit || availableField.UseFahrenheit;
            existingField.UseGallons = existingField.UseGallons || availableField.UseGallons;

            return existingField;
        })
        .filter(x => x != null);
    }

    // Should not be able to edit the required HOT field for one step
    requireHotIsDisabled(): boolean {
        return this.item.Steps === 1;
    }

    selectSteps(n) {
        this.item.Steps = n;
        this.item.IsHOTDeicing = n === 2;
    }
}
